import axios from 'axios';
const baseDomain = `${process.env.REACT_APP_API_URL}`;
const baseURL = `${baseDomain}`;

const getToken = () => {
	let ls = JSON.parse(localStorage.getItem('auth'));
	// console.log('ls', ls);
	if (ls && ls.token != null) {
		return `Bearer ${ls.token}`;
	} else {
		return '';
	}
};

let axiosObj;
axiosObj = axios.create({
	baseURL,
	headers: {
		Authorization: getToken(),
	},
});

export default axiosObj;
