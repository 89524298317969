import React, {useEffect} from 'react';
import exit from '../../assets/icon/exit.svg';
import bar from '../../assets/icon/bar.svg';
import logo from '../../assets/img/logo.svg';
import footerLogo from '../../assets/img/footer-logo.svg';
import property1 from '../../assets/img/properties/property-details-img-gallery-img1.png';
import property2 from '../../assets/img/properties/property-details-img-gallery-img2.png';
import property3 from '../../assets/img/properties/property-details-img-gallery-img3.png';
import PlayBtn from '../../assets/img/properties/play-button.svg';
import clock from '../../assets/icon/clock-w.svg';
import phone from '../../assets/icon/phone-w.svg';
import fbs from '../../assets/img/social/fb.svg';
import INs from '../../assets/img/social/in.svg';
import INS from '../../assets/img/social/ins.svg';
import tws from '../../assets/img/social/tw.svg';
import mapMarker from '../../assets/icon/map-marker.svg';
import mMarkerW from '../../assets/icon/m-marker-w.svg';
import enw from '../../assets/icon/en-w.svg';
import mapImg from '../../assets/img/map.png';

import {Link, useHistory} from 'react-router-dom';
import {Container} from 'reactstrap';
import Footer from '../../components/global/Footer';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {getPropertyDetails} from '../../store/actions/propertiesAction';
import ProjectDescriptionHead from '../../components/projectDetails/ProjectDescriptionHead';
import ImgGallery from '../../components/projectDetails/ImgGallery';
import ProjectDescriptionTabularBody from '../../components/projectDetails/ProjectDescriptionTabularBody';
import OpportunityCard from '../../components/projectDetails/OpportunityCard';


function ProjectDetailsView() {
    const dispatch = useDispatch();
    const {id} = useParams();
    const {propertyDetails} = useSelector(state => state.properties);

    useEffect(() => {
        dispatch(getPropertyDetails(id));
    }, []);
    return (
        <>
            <div className='main-wrapper'>
                <div className='inner-wrapper bg-white'>
                    <div className='pagination-section pagination-section__marginTop pt-5 pb-3'>
                        <Container lg='fluid'>
                            <div className='pagination-section-inner'>
                                <div className='pagination'>
                                    <ul>
                                        <li>
                                            <a href='#'>Home</a> /{' '}
                                        </li>
                                        <li>
                                            <Link to='/projects'> All Projects</Link> /{' '}
                                        </li>
                                        <li>
                                            <a className='active' href='#'>
                                                {propertyDetails.title}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </Container>
                    </div>
                    {/* <!-- pagination-end --> */}

                    {/* <!-- property-details-section --> */}
                    <section className='property-details-section'>
                        <Container lg='fluid'>
                            <div className='property-details-section-inner'>
                                {/* <div className="property-details-img-slider property-details-img-slider-active owl-carousel d-lg-none slider-arrow1 slider-arrow-center owl-dots1">
                  <div className="property-details-img-slide">
                    <div className="image">
                      <div
                        className="image-inner"
                        style={{ backgroundImage: `url(${property1})` }}
                        // style="background: url('assets/img/properties/property-details-img-gallery-img1.png') no-repeat center center/cover;"
                      ></div>
                    </div>
                  </div>
                  <div className="property-details-img-slide">
                    <div className="image">
                      <div
                        className="image-inner"
                        style={{ backgroundImage: `url(${property1})` }}
                        // style="background: url('assets/img/properties/property-details-img-gallery-img1.png') no-repeat center center/cover;"
                      ></div>
                    </div>
                  </div>
                  <div className="property-details-img-slide">
                    <div className="image">
                      <div
                        className="image-inner"
                        style={{ backgroundImage: `url(${property1})` }}
                        // style="background: url('assets/img/properties/property-details-img-gallery-img1.png') no-repeat center center/cover;"
                      ></div>
                    </div>
                  </div>
                  <div className="property-details-img-slide">
                    <div className="image">
                      <div
                        className="image-inner"
                        style={{ backgroundImage: `url(${property1})` }}
                        // style="background: url('assets/img/properties/property-details-img-gallery-img1.png') no-repeat center center/cover;"
                      ></div>
                    </div>
                  </div>
                </div> */}

                                <ProjectDescriptionHead data={propertyDetails}/>

                                <ImgGallery/>

                                <div className='property-details-row'>
                                    <ProjectDescriptionTabularBody data={propertyDetails}/>

                                    <OpportunityCard data={propertyDetails}/>
                                </div>
                            </div>
                        </Container>
                    </section>
                    {/* <!-- property-details-section-end --> */}
                </div>
                {/* <!-- wrapper-inner --> */}
                <Footer/>
            </div>

            {/* <!-- modal --> */}
            <div
                className='modal contact-modal fade'
                id='exampleModal'
                tabIndex='-1'
                aria-labelledby='exampleModalLabel'
                aria-hidden='true'
            >
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content'>
                        <div className='contact-form'>
                            <form action=''>
                                <div className='row'>
                                    <div className='col-md-12 col-lg-6'>
                                        <div className='input-item'>
                                            <label>First Name*</label>
                                            <div className='input-box'>
                                                <input
                                                    type='text'
                                                    placeholder='type your first name here...'
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-12 col-lg-6'>
                                        <div className='input-item'>
                                            <label>Last Name*</label>
                                            <div className='input-box'>
                                                <input
                                                    type='text'
                                                    placeholder='type your last name here...'
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <div className='input-item'>
                                            <label>Contact Number*</label>
                                            <div className='input-box'>
                                                <input
                                                    type='text'
                                                    placeholder='type your number here...'
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <div className='input-item'>
                                            <label>Message*</label>
                                            <div className='input-box'>
												<textarea
                                                    name=''
                                                    id=''
                                                    cols='30'
                                                    rows='10'
                                                    placeholder='type your message here...'
                                                ></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div className='text-center submit-btn mt-4'>
                                            <a href='' className='op-btn'>
                                                Send
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- modal-end --> */}
        </>
    );
}

export default ProjectDetailsView;
