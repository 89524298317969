import React, { useEffect } from 'react';
import shapeTr from '../../assets/img/properties/shape-tr.svg';
import shapeBl from '../../assets/img/properties/shape-bl.svg';

import { Container } from 'reactstrap';
import Footer from '../../components/global/Footer';
import { getProperties } from '../../store/actions/propertiesAction';
import { useDispatch, useSelector } from 'react-redux';
import {} from 'react-redux';
import Switch from '../../components/projects/Switch';
import ProjectItem from '../../components/projects/projectsList';


function Properties() {
	const { properties } = useSelector(state => state.properties);
	// console.log('properties', properties);
	const dispatch = useDispatch();
	// const images = [propertie2, propertie3, propertie4, propertie5];
	useEffect(() => {
		dispatch(getProperties());
	}, []);
	return (
		<>
			<div className='main-wrapper'>
				<div className='inner-wrapper position-relative bg-white'>
					<div className='pagination-section pagination-section__marginTop pt-5 pb-3'>
						<Container lg='fluid'>
							<div className='pagination-section-inner'>
								<div className='pagination'>
									<ul>
										<li>
											<a href='#'>Home</a>/
										</li>
										<li>
											<a className='active' href='#'>
												All Projects
											</a>
										</li>
									</ul>
								</div>
							</div>
						</Container>
					</div>
					{/* <!-- pagination-end --> */}

					{/* <!-- properties-section --> */}
					<section className='properties-section'>
						<img
							src={shapeTr}
							alt=''
							className='prop-shape prop-shape-tr'
						/>
						<img
							src={shapeBl}
							alt=''
							className='prop-shape prop-shape-bl'
						/>
						<Container lg='fluid'>
							<div className='properties-section-inner'>
								{/*TODO: Switch between open/closed/paused properties*/}
								{/*<Switch />*/}
								<div className='all-properties-wrap'>
									{/*<!-- single --> */}
									{/*<div className='sgl-properties'>*/}
									{/*	<div className='fl-image'>*/}
									{/*		<Link to='/property-details'>*/}
									{/*			<img src={propertie1} alt='' />*/}
									{/*		</Link>*/}
									{/*	</div>*/}
									{/*</div>*/}
									<ProjectItem properties={properties} />

									{/* <!-- single-end --> */}
									{/* <!-- single --> */}
									{/*{images.map((el, id) => {*/}
									{/*	// console.log('el', el);*/}
									{/*	return (*/}
									{/*		<div key={id}>*/}
									{/*			<ImgCard images={el} />*/}
									{/*		</div>*/}
									{/*	);*/}
									{/*})}*/}
								</div>
							</div>
						</Container>
					</section>
					{/* <!-- properties-section-end --> */}
					{/* </main> */}
					{/* <!-- main-wrapper-end --> */}

					{/* <!-- footer-area --> */}

					{/* <!-- footer-area-end --> */}
				</div>
				{/* <!-- wrapper-inner --> */}
				<Footer />
			</div>

			{/* <!-- modal --> */}
			<div
				className='modal contact-modal fade'
				id='exampleModal'
				tabIndex='-1'
				aria-labelledby='exampleModalLabel'
				aria-hidden='true'
			>
				<div className='modal-dialog modal-dialog-centered'>
					<div className='modal-content'>
						<div className='contact-form'>
							<form action=''>
								<div className='row'>
									<div className='col-md-12 col-lg-6'>
										<div className='input-item'>
											<label>First Name*</label>
											<div className='input-box'>
												<input
													type='text'
													placeholder='type your first name here...'
												/>
											</div>
										</div>
									</div>
									<div className='col-md-12 col-lg-6'>
										<div className='input-item'>
											<label>Last Name*</label>
											<div className='input-box'>
												<input
													type='text'
													placeholder='type your last name here...'
												/>
											</div>
										</div>
									</div>
									<div className='col-md-12'>
										<div className='input-item'>
											<label>Contact Number*</label>
											<div className='input-box'>
												<input
													type='text'
													placeholder='type your number here...'
												/>
											</div>
										</div>
									</div>
									<div className='col-md-12'>
										<div className='input-item'>
											<label>Message*</label>
											<div className='input-box'>
												<textarea
													name=''
													id=''
													cols='30'
													rows='10'
													placeholder='type your message here...'
												></textarea>
											</div>
										</div>
									</div>
									<div className='col-12'>
										<div className='text-center submit-btn mt-4'>
											<a href='' className='op-btn'>
												Send
											</a>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			{/* <!-- modal-end --> */}
		</>
	);
}

export default Properties;
