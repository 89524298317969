import {useHistory} from 'react-router-dom';

function MainCard(props) {
    const history = useHistory();
    // TODO: support _target=blank with useHref() provided by react-router_v > 6
    return (
        <div className="statistic-single pink-theme" onClick={() => history.push(props.path)}>
            <div className="icon">
                <img src={props.img} alt=""/>
            </div>
            <div className="content text-center">
                <h3>{props.title}</h3>
                <p>{props.description}</p>
            </div>
        </div>
    );
}

export default MainCard;
