import Repository from './Repository';

const MESSAGE = '/message';

export default {
	getMessage(id) {
		return Repository.get(`${MESSAGE}`);
	},
	updateMessage(id, payload) {
		return Repository.put(`${MESSAGE}/${id}`, payload);
	},
};
