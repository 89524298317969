import {icon} from '@fortawesome/fontawesome-svg-core';
import icon1 from '../assets/icon/icon1.svg';
import icon2 from '../assets/icon/icon2.svg';
import icon3 from '../assets/icon/icon3.svg';
import icon4 from '../assets/icon/icon4.svg';
import ActivityCard from '../components/dashboard/ActivityCard';
import AnalyticsCard from '../components/dashboard/AnalyticsCard';
import MainCard from '../components/dashboard/MainCard';
import pai from '../assets/img/pai.png';
import OpportunityCard from '../components/dashboard/OpportunityCard';
import InvestmentCard from '../components/dashboard/InvestmentCard';
import TransactionTable from '../components/transactions/TransactionTable';
import {useEffect} from 'react';
import {getTransaction} from '../store/actions/transactionAction';
import {useDispatch, useSelector} from 'react-redux';
import {getInvestment} from "../store/actions/InvestmentAction";
import {getProperties} from "../store/actions/propertiesAction";
import {getMessageAct} from "../store/actions/messageAction";

const AdminView = props => {

    const dispatch = useDispatch();
    const {transactionList} = useSelector(state => state.transaction);
    const {investment} = useSelector(state => state.investment);
    const {properties} = useSelector(state => state.properties);
    const {message} = useSelector(state => state.message);

    const cardData = [
        {
            img: icon1,
            title: properties.length,
            description: 'Open opportunities',
            path: '/projects'
        },
        {
            img: icon2,
            title: investment.length,
            description: 'Projects I\'ve invested',
            path: '/my-investments'
        },
        {
            img: icon3,
            title: transactionList.length,
            description: 'Transactions',
            path: '/transactions'
        },
        {
            img: icon4,
            title: !message ? 0 : (message.rows ? message.rows.length : 0),
            description: 'Messages',
            path: '/messages'
        },
    ];


    useEffect(() => {
        dispatch(getTransaction());
        dispatch(getInvestment());
        dispatch(getProperties());
        dispatch(getMessageAct());
    }, []);
    return (
        <>
            <div className='page-title-mobile d-xl-none'>
                <h3 className='mb-0'>Overview</h3>
            </div>

            <div className='statistics-wrap'>
                {cardData.map((el, id) => {
                    return (
                        <MainCard
                            data={el.img}
                            title={el.title}
                            description={el.description}
                            key={id}
                            path={el.path}
                        />
                    );
                })}
            </div>

            {/*<div className='analytics-row'>*/}
            {/*	/!*<ActivityCard img={graph} />*!/*/}
            {/*	/!*<AnalyticsCard img={pai} />*!/*/}
            {/*</div>*/}

            <div className='s-card-row'>
                <OpportunityCard projects={properties}/>
                <InvestmentCard investment={investment} projects={properties}/>
            </div>

            <TransactionTable data={transactionList}/>
        </>
    );
};

export default AdminView;
