import { RepositoryFactory } from "../../repository/RepositoryFactory";
var message = RepositoryFactory.get("message");

export const getMessageAct = (payload) => async (dispatch) => {
  try {
    let { data } = await message.getMessage(payload);

    if (data) {
      data.rows = data.rows.sort((a, b) => a.createdAt - b.createdAt); // b - a for reverse sort
      await dispatch({
        type: "GET_MESSAGE",
        payload: data,
      });
    }
  } catch (e) {
    console.log("Error", e);
  }
};
export const updateMessageAct = (id, payload) => async (dispatch) => {
  try {
    await message.updateMessage(id, payload);
  } catch (e) {
    console.error('Message cannot be updated.')
  }
};
