import red from "../assets/icon/i-red.svg";
import { useRef, useState } from "react";
import { useEffect } from "react";
import { getMessageAct } from "../store/actions/messageAction";
import { useDispatch, useSelector } from "react-redux";
import Table from "../components/messages/Table";
const Messages = () => {
  const refOne = useRef(null);
  const [showPicker, setShowPicker] = useState(false);

  const dispatch = useDispatch();
  const { message } = useSelector((state) => state.message);
  // console.log('message', message);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    dispatch(getMessageAct());
  }, []);

  useEffect(() => {
    updatePredicate();
    window.addEventListener("resize", updatePredicate);
    return () => window.removeEventListener("resize", updatePredicate);
  }, []);
  const updatePredicate = () => {
    setIsSmallScreen(window.innerWidth < 1024);
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, {
      capture: true,
    });
    return () => {
      document.removeEventListener("click", handleClickOutside, {
        capture: true,
      });
    };
  }, []);
  const handleClickOutside = (e) => {
    if (!refOne?.current?.contains(e?.target)) {
      setShowPicker(false);
    }
  };

  const data = [
    {
      date: "27-09-2021",
      subject: "Please update your KYC",
      messageType: "prio0",
      content: "We hereby request you to send update your account section.",
      marked: true,
    },
    {
      date: "27-09-2021",
      subject: "Please update your KYC",
      messageType: "prio0",
      content: "We hereby request you to send update your account section.",
      marked: true,
    },
    {
      date: "27-09-2021",
      subject: "Please update your KYC",
      messageType: "prio0",
      content: "We hereby request you to send update your account section.",
      marked: true,
    },
  ];
  return (
    <>
      {/* <!-- page-title-mobile --> */}
      <div className="page-title-mobile d-xl-none">
        <h3 className="mb-0">Messages</h3>
      </div>

      <Table data={message} />
    </>
  );
};

export default Messages;
