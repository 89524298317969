const initialData = {
  token: "",
  successMessage: "",
  errorMessage: "",
  loginSuccessMessage: "",
  user: {},
};

const authUserReducer = (state = initialData, action) => {
  // console.log('action', action.payload);
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return {
        ...state,
        token: action.payload,
      };
    case "LOGIN_SUCCESS_MSG":
      return {
        ...state,
        loginSuccessMessage: action.payload,
      };
    case "OTP_SUCCESS":
      return {
        errorMessage: "",
        successMessage: "OTP verification successful. Taking you to the Dashboard.",
      };
    case "LOGIN_FAILED":
      return {
        errorMessage: "OTP verification failed. Please try again.",
        successMessage: "",
      };
    case "LOGOUT_SUCCESS":
      return {
        ...state,
        token: "",
        user: {},
      };

    default:
      return state;
  }
};

export default authUserReducer;
