import projectImage from '../../assets/img/new-opportunity.png';
import locationTheme from '../../assets/icon/location-theme2.svg';
import { prettyCurrency } from '../Utilities';

function OpportunityCard(props) {

    if (!props.projects || props.projects.length === 0) {
        return <></>;
    }

    const project = props.projects[0];
    return (
        <div className='s-card'>
            <div className='s-card-header'>
                <h4 className='mb-0'>New opportunity</h4>
            </div>
            <div className='s-card-in-content'>
                <div className='s-card-row1'>
                    <div className='image'>
                        <img src={projectImage} alt=''/>
                    </div>
                    <div className='text-side'>
                        <h3 className='h3'>{project.title}</h3>
                        <p className='location-text'>
                            <img
                                src={locationTheme}
                                alt=''
                                className='im'
                            />
                            <span className='txt'>{project.location}</span>
                        </p>
                    </div>
                </div>
                <div className='s-card-states-wrap'>
                    <div className='sc-state-single'>
                        <h4>{project.roi}</h4>
                        <p>Target ROI</p>
                    </div>
                    <div className='sc-state-single'>
                        <h4>{project.durationMonths}</h4>
                        <p>Estimated Duration</p>
                    </div>
                    <div className='sc-state-single'>
                        <h4>{prettyCurrency(project.budget)}</h4>
                        <p>Total Budget</p>
                    </div>
                </div>
                <div className='bt-button-row'>
                    <a
                        href={process.env.REACT_APP_FRONTEND_URL + '#join'}
                        className='default-button default-button--theme2 min-w'
                        target='_blank'
                    >
                        Invest Now
                    </a>
                </div>
            </div>
        </div>
    );
}

export default OpportunityCard;
