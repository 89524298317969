import { RepositoryFactory } from "../../repository/RepositoryFactory";
import { toast } from "react-toastify";
var account = RepositoryFactory.get("account");
export const updateAddressLoader = (val) => async (dispatch) => {
  dispatch({ type: "ADDRESS_LOADER", payload: val });
};
export const updatePanLoader = (val) => async (dispatch) => {
  dispatch({ type: "PAN_LOADER", payload: val });
};
export const updateBankLoader = (val) => async (dispatch) => {
  dispatch({ type: "BANK_LOADER", payload: val });
};
export const updateNomineeLoader = (val) => async (dispatch) => {
  dispatch({ type: "NOMINEE_LOADER", payload: val });
};
export const updateUserLoader = (val) => async (dispatch) => {
  dispatch({ type: "USER_LOADER", payload: val });
};
export const updatePersonalDetailsLoader = (val) => async (dispatch) => {
  dispatch({ type: "PERSONAL_DETAILS_LOADER", payload: val });
};

export const getAddress = (payload) => async (dispatch) => {
  try {
    // await dispatch(getAdminLoading(true));
    let { data } = await account.getAddress(payload);
    // console.log('data', data);

    if (data) {
      await dispatch({
        type: "GET_ADDRESS",
        payload: data,
      });
    }
    // dispatch(getAdminLoading(false));
  } catch (e) {
    // dispatch(getAdminLoading(false));
    // alert(e.response.data.message);
    // toast.error("Address not found");
  }
};
export const updateAddress =
  (id, obj, onSuccess = () => {}) =>
  async (dispatch) => {
    dispatch(updateAddressLoader(true));
    try {
      // await dispatch(getAdminLoading(true));
      let { data } = await account.updateAddress(id, obj);
      // console.log("data", data);
      if (data) {
        //   toast.success("Address updated successfully");
        // alert("Admin updated successfully!");
        await dispatch({
          type: "ADDRESS_SUCCESS",
          payload: "Updated Address !",
        });
        onSuccess();
        dispatch({
          type: "EMPTY",
        });
      }
      dispatch(updateAddressLoader(false));
    } catch (e) {
      dispatch(updateAddressLoader(false));
      // toast.error("Address not updated ! error");
      await dispatch({
        type: "ADDRESS_SUCCESS",
        payload: " Error ! The server doesnt reached !",
      });
      onSuccess();
    }
  };
export const addAddress = (obj) => async (dispatch) => {
  dispatch(updateAddressLoader(true));
  try {
    // await dispatch(getAdminLoading(true));
    let { data } = await account.addAddress(obj);
    // console.log('data', data);
    if (data) {
      //   toast.success("Address added successfully");
      dispatch({
        type: "ADDRESS_ADDED_SUCCESS",
        payload: "Updated Address",
      });

      // alert("Admin updated successfully!");
    }
    dispatch(updateAddressLoader(false));
  } catch (e) {
    dispatch(updateAddressLoader(false));
    // toast.error("Address not updated ! error");
    dispatch({
      type: "ADDRESS_FAILED",
      payload: "Failed to update Address!",
    });
  }
};
export const updatePanDetails =
  (payload, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      dispatch(updatePanLoader(true));
      if (payload.panImage) {
        let { data } = await account.getPanImageUrl(payload.panImage);
        let s3Response = await account.uploadPanImageToS3({
          url: data,
          file: payload.panImage,
        });
        if (s3Response.status == 200) {
          let { data: s3Data } = await account.updatePanDetails({
            data: {
              number: payload.panNumber,
              isPanCardUploaded: true,
            },
          });
          onSuccess();
          dispatch(updatePanLoader(false));
          await dispatch({
            type: "PAN_SUCCESS",
            payload: "Updated Pan details",
          });
          onSuccess();
        } else {
          dispatch(updatePanLoader(false));
          console.error("Couldn't upload Pan card to S3.");
          await dispatch({
            type: "PAN_FAILED",
            payload: "Failed updating Pan details",
          });
          onSuccess();
        }
      } else {
        if (payload.type == "update") {
          let { data } = await account.updatePanDetails({
            data: { number: payload.panNumber },
          });
        } else {
          let { data } = await account.updatePanDetails({
            data: {
              number: payload.panNumber,
              isPanCardUploaded: false,
            },
          });
        }
        dispatch(updatePanLoader(false));
        onSuccess();
        // console.log(dispatch(updatePanLoader(false)));
        // toast.success("Pan Details updated successfully!");
        // alert("Pan Details updated successfully!");
        dispatch({
          type: "PAN_SUCCESS",
          payload: "Updated Pan Details",
        });
      }
    } catch (e) {
      // alert(e.response.data.message);
      dispatch(updatePanLoader(false));
      //   toast.error("pan details not updated something wrong");
      dispatch({
        type: "PAN_FAILED",
        payload: "Error ! Server not reached",
      });
    }
  };
// export const addPanDetails =
// (payload, onSuccess = () => {}) =
// async (dispatch) => {
//   try {
//     dispatch(updatePanLoader(true));
//     if (payload.panImage) {
//       let { data } = await account.getPanImageUrl(payload.panImage);
//       let s3Response = await account.uploadPanImageToS3({
//         url: data,
//         file: payload.panImage,
//       });
//       if (s3Response.status == 200) {
//         let { data: s3Data } = await account.updatePanDetails({
//           data: {
//             number: payload.panNumber,
//             isPanCardUploaded: true,
//           },
//         });
//         onSuccess();
//         dispatch(updatePanLoader(false));
//         //   toast.success("Pan Details updated successfully!");
//         // alert("Pan Details updated successfully!");
//       } else {
//         // alert("Error in updating pan details!");
//         dispatch(updatePanLoader(false));
//         //   toast.error("Error in updating pan details!");
//       }
//     } else {
//       let { data } = await account.updatePanDetails({
//         data: { number: payload.panNumber },
//       });
//       onSuccess();
//       console.log(dispatch(updatePanLoader(false)));
//       // toast.success("Pan Details updated successfully!");
//       // alert("Pan Details updated successfully!");
//     }
//   } catch (e) {
//     // alert(e.response.data.message);
//     dispatch(updatePanLoader(false));
//     //   toast.error("pan details not updated something wrong");
//   }
// };

export const getPanDetails = (payload) => async (dispatch) => {
  try {
    // await dispatch(getAdminLoading(true));
    let { data, status } = await account.getPanDetails(payload);
    // console.log("data", data);
    if (status == 200 && data != "") {
      await dispatch({
        type: "GET_PAN_DETAILS",
        payload: data,
      });
    } else {
      await dispatch({
        type: "GET_PAN_DETAILS",
        payload: {},
      });
    }

    // dispatch(getAdminLoading(false));
  } catch (e) {
    // dispatch(getAdminLoading(false));
    // alert(e.response.data.message);
    console.error("Couldn't get Pan details");
  }
};
export const getAccountDetailsAct = (payload) => async (dispatch) => {
  try {
    // await dispatch(getAdminLoading(true));
    let { data } = await account.getAccountDetails(payload);
    // console.log('data', data);
    if (data) {
      await dispatch({
        type: "GET_ACCOUNT_DETAILS",
        payload: data[0],
      });
    }
    // dispatch(getAdminLoading(false));
  } catch (e) {
    // dispatch(getAdminLoading(false));
    console.error("Couldn't get Account details");
  }
};
export const getAllBankAccounts = (payload) => async (dispatch) => {
  try {
    let { data } = await account.getAllBankAcconts(payload);

    if (data) {
      await dispatch({
        type: "GET_ALL_BANK_ACCOUNTS",
        payload: data,
      });
    }
  } catch (e) {
    console.error("Couldn't get Bank Account details", e);
  }
};
export const updateAccountDetails =
  (id, obj, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      dispatch(updateBankLoader(true));
      let { data } = await account.updateAccountDetails(id, obj);

      if (data) {
        dispatch(updateBankLoader(false));
        await dispatch({
          type: "BANK_SUCCESS",
          payload: "Updated Account details",
        });
        onSuccess();
      }
    } catch (e) {
      console.error("Couldn't update Account details.", e);
      dispatch({
        type: "BANK_FAILED",
        payload: "Failed updating Account details",
      });
      await dispatch(updateBankLoader(false));
      onSuccess();
    }
  };
export const addAccountDetails =
  (obj, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      dispatch(updateBankLoader(true));
      let { data } = await account.addAccountDetails(obj);

      if (data) {
        dispatch(updateBankLoader(false));
        dispatch({
          type: "ACCOUNT_ADD_SUCCESS",
          payload: "Updated Account details",
        });

      }
      onSuccess();
    } catch (e) {
      console.error("Failed updating Account details", e);
      dispatch({
        type: "BANK_ADD_FAILED",
        payload: "Failed updating Account details",
      });

      dispatch(updateBankLoader(false));
    }
  };
export const getNomineeDetailsAct = (payload) => async (dispatch) => {
  try {
    let { data } = await account.getNomineeDetails(payload);
    if (data) {
      await dispatch({
        type: "GET_NOMINEE_DETAILS",
        payload: data.rows[0],
      });
    }
  } catch (e) {
    console.error("Couldn't get Nominee details");
  }
};
export const getListNomineeDetailsAct = (payload) => async (dispatch) => {
  try {
    let { data } = await account.getListNomineeDetails(payload);
    // console.log("nominee data", data);

    if (data) {
      await dispatch({
        type: "GET_LIST_NOMINEE_DETAILS",
        payload: data.rows,
      });
    }
  } catch (e) {
    // toast.error('nominee details not get successfully');
  }
};
export const updateNomineeDetails =
  (id, obj, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      dispatch(updateNomineeLoader(true));

      let { data } = await account.updateNomineeDetails(id, obj);

      if (data) {
        dispatch(updateNomineeLoader(false));
        dispatch({
          type: "NOMINEE_SUCCESS",
          payload: "Updated Nominee",
        });
        onSuccess();
      }
    } catch (e) {
      console.error("Nominee not updated successfully", e);
      dispatch(updateNomineeLoader(false));
      dispatch({
        type: "NOMINEE_FAILED",
        payload: "Failed updating Nominee",
      });
      onSuccess();
    }
  };
export const addNomineeDetails =
  (obj, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      dispatch(updateNomineeLoader(true));

      let { data } = await account.addNomineeDetails(obj);

      if (data) {
        dispatch(updateNomineeLoader(false));
        onSuccess();
        dispatch({
          type: "NOMINEE_ADD_SUCCESS",
          payload: "Updated Nominee",
        });
      }
    } catch (e) {
      console.error("Failed updating Nominee", e);
      dispatch({
        type: "NOMINEE_ADD_FAILED",
        payload: "Failed updating Nominee",
      });

      dispatch(updateNomineeLoader(false));
      onSuccess();
    }
  };
export const getPersonalDetailsAct = (history) => async (dispatch) => {
  try {
    let { data } = await account.getPersonalDetails();

    if (data) {
      await dispatch({
        type: "PERSONAL_DETAILS",
        payload: data,
      });
    }
  } catch (e) {
    console.error("Failed updating Personal details", e);
    if (e.response?.status == 401) {
      localStorage.clear();
      dispatch({ type: "LOGOUT_SUCCESS" });
      history.push("/");
    } else {
      dispatch({
        type: "PERSONAL_FAILED",
        payload: "Failed updating Personal details",
      });
    }
  }
};
export const updatePersonalDetails =
  (payload, onSuccess = () => {}, img) =>
  async (dispatch) => {
    try {
      dispatch(updatePersonalDetailsLoader(true));
      if (img) {
        let { data } = await account.getPersonalDetailsImage();
        let s3Response = await account.uploadPanImageToS3({
          url: data,
          file: img,
        });
        if (s3Response.status == 200) {
          let { data: s3Data } = await account.updatePersonalDetails({
            data: {
              ...payload,
              isProfilePicUploaded: true,
            },
          });

          dispatch(updatePersonalDetailsLoader(false));
          onSuccess("success");
        } else {
          dispatch(updatePersonalDetailsLoader(false));
          onSuccess("fail");
          console.error("Failed updating personal details", s3Response);
        }
      } else {
        let { data } = await account.updatePersonalDetails({
          data: { ...payload },
        });
        onSuccess("success");
        dispatch(updatePersonalDetailsLoader(false));
      }
    } catch (e) {
      onSuccess("fail");
      dispatch(updatePersonalDetailsLoader(false));
      console.error("Failed updating Pan details");
    }
  };
export const updatePersonalDetail =
  (payload, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      dispatch(updateUserLoader(true));

      let { data } = await account.updatePersonalDetails(payload);

      if (data) {
        dispatch(updateUserLoader(false));
        onSuccess("pass");
        dispatch({ type: "PERSONAL_SUCCESS" });

      }
    } catch (e) {
      console.error("Failed updating personal details", e);
      dispatch(updateUserLoader(false));
      onSuccess("fail");
      dispatch({ type: "PERSONAL_FAILED" });

    }
  };
