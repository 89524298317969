import React from 'react';
import Navbar from '../components/global/Navbar';

function Public(props) {
	return (
		<div>
			<Navbar />
			{props.children}
		</div>
	);
}

export default Public;
