import { RepositoryFactory } from "../../repository/RepositoryFactory";
var investment = RepositoryFactory.get("investment");

export const getInvestment = () => async (dispatch) => {
  try {
    let { data } = await investment.getInvestment();
    // console.log("data", data);

    if (data) {
      await dispatch({
        type: "GET_INVESTMENT",
        payload: data.rows,
      });
    }
  } catch (e) {
    // alert(e.response.data.message);
  }
};

