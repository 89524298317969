import { el } from "date-fns/locale";
import { RepositoryFactory } from "../../repository/RepositoryFactory";
var transaction = RepositoryFactory.get("transaction");
var investment = RepositoryFactory.get("investment");

export const addSupportLoader = (val) => async (dispatch) => {
  dispatch({ type: "SUPPORT_LOADER", payload: val });
};

export const getTransaction = (obj) => async (dispatch) => {
  try {
    // dispatch(addSupportLoader(true));

    let { data } = await transaction.getTransaction(obj);
    let temp = [];
    if (data) {
      for (let index = 0; index < data.rows.length; index++) {
        const element = data.rows[index];
        try {
          let resp = await investment.getClientInvestment(
            element.clientInvestmentId
          );
          temp.push({ ...element, project: resp.data.project });
        } catch (e) {
          // alert(e.response.data.message);
        }
      }
      dispatch({ type: "GET_TRANSACTION", payload: temp });
      // dispatch(addSupportLoader(false));
    }
  } catch (e) {
    // alert(e.response.data.message);
    // dispatch(addSupportLoader(false));
  }
};
