const initialData = {
	investment: [],
	clientInvestment: [],
};

const investmentReducer = (state = initialData, action) => {
	// console.log('action', action.payload);
	switch (action.type) {
		case 'GET_INVESTMENT':
			return {
				...state,
				investment: action.payload,
			};
		default:
			return state;
	}
};

export default investmentReducer;
