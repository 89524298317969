import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Button, Spinner } from "reactstrap";
import { getSupportAct } from "../../store/actions/getSupportAction";
import { getCurrentUser } from "../../store/actions/userAction";

const GetSupportForm = () => {
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const { loading } = useSelector((state) => state.support);
  const { currentUser } = useSelector((state) => state.user);
  const [visible, setVisible] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);

  const { errorMessage, successMessage } = useSelector(
    (state) => state.account
  );
  // console.log('currentUser', currentUser);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCurrentUser());
  }, []);

  return (
    <>
      {/* <!-- account-box --> */}
      <div className="account-box account-box-contact">
        <form
          onSubmit={(e) => {
            e.preventDefault();

            const data = {
              subject: subject,
              message: message,
              contactNumber: currentUser.phoneNumber,
              email: currentUser.email,
            };
            let obj = { data: data };
            dispatch(
              getSupportAct(obj, () => {
                setVisible(true);
                setTimeout(() => {
                  setVisible(false);
                }, 3000);
              })
            );

            setSubject("");
            setMessage("");
          }}
        >
          {/* <!-- account-c-group --> */}
          <div className="account-c-group">
            <a href="#" className="acc-grp-title d-md-none">
              <h3>Contact us for support</h3>
            </a>
            {visible ? (
              <h3 className="text-left text-success my-5">
                {successMessage ? (
                  successMessage
                ) : (
                  <h3 className="text-danger">{errorMessage}</h3>
                )}
              </h3>
            ) : (
              ""
            )}

            {/* {error ? <h3>{error}</h3> : ""} */}
            <div className="acc-grp-m-content">
              <div className="acc-grp-m-content-inner">
                <div className="acc-grp-form-row pt-md-4">
                  <div className="column">
                    <div className="single-input-x">
                      <label className="label-x">Message Title</label>
                      <div className="input-x-outer">
                        <input
                          type="text"
                          className="input-x"
                          required
                          value={subject}
                          onChange={(e) => setSubject(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div className='column'>
										<div className='single-input-x'>
											<label className='label-x'>
												The Topic*
											</label>
											<div className='input-x-outer'>
												<select className='input-x'>
													<option>
														Select the topic
													</option>
													<option>
														Select the topic
													</option>
													<option>
														Select the topic
													</option>
												</select>
											</div>
										</div>
									</div> */}
                  <div className="column column-dbl-column pt-md-3">
                    <div className="single-input-x">
                      <label className="label-x">Message</label>
                      <div className="input-x-outer">
                        <textarea
                          type="text"
                          required
                          className="input-x input-x-big"
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- account-c-group-end --> */}

          <div className="acc-form-bt-row d-flex justify-content-center">
            <Button
              type="submit"
              className="default-button min-w default-button--theme2"
            >
              {loading ? <Spinner size="sm" /> : "Save changes"}
            </Button>
          </div>
        </form>
      </div>
      {/* <!-- account-box-end --> */}
    </>
  );
};

export default GetSupportForm;
