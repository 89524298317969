function TransactionPagination(props) {
	// console.log('props', props.data.length);
	return (
		<>
			<div className='table-box-footer'>
				<div className='td-pagination'>
					<div className='pagination'>
						<div className='left'>
							<p className='mb-0'>
								Showing{' '}
								<span className='fw-600'>
									{props.data?.length}
								</span>
								{/* of{' '}
								<span className='fw-600'>30</span> */}
							</p>
						</div>
						{/* <div className='right'>
							<ul className='pagi-nav'>
								<li className='corner'>
									<a href='javascript:void(0)'>Previous</a>
								</li>
								<li>
									<a
										className='fw-500'
										href='javascript:void(0)'
									>
										1
									</a>
								</li>
								<li>
									<a
										className='fw-500 active'
										href='javascript:void(0)'
									>
										2
									</a>
								</li>
								<li>
									<a
										className='fw-500'
										href='javascript:void(0)'
									>
										3
									</a>
								</li>
								<li className='corner'>
									<a href='javascript:void(0)'>Next</a>
								</li>
							</ul>
						</div> */}
					</div>
				</div>
			</div>
		</>
	);
}

export default TransactionPagination;
