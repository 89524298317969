const initialData = {
  transactionList: [
    // 	{
    // 	"id": "3f811cd6-f75f-4c07-b2fd-09b04d14e2d0",
    // 	"date": "27-09-2021",
    // 	"amount": "90000.00",
    // 	"mode": "neft",
    // 	"type": "credit",
    // 	"details": "From HDFC Account",
    // 	"importHash": null,
    // 	"createdAt": "2022-10-22T06:43:05.754Z",
    // 	"updatedAt": "2022-10-22T06:43:05.754Z",
    // 	"deletedAt": null,
    // 	"clientInvestmentId": "7653f62a-7e1d-48b3-ac4c-57856ca0fba4",
    // 	"clientId": "d2b49f67-f545-4618-847a-94262b755865",
    // 	"createdById": "fd3dca15-a2f7-4014-8c93-92a0aa19cf69",
    // 	"updatedById": "fd3dca15-a2f7-4014-8c93-92a0aa19cf69"
    // }
  ],
};

const getSupportReducer = (state = initialData, action) => {
  // console.log('action', action.payload);
  switch (action.type) {
    case "GET_TRANSACTION":
      return {
        ...state,
        transactionList: action.payload,
      };

    default:
      return state;
  }
};

export default getSupportReducer;
