// import { RepositoryFactory } from 'repository/RepositoryFactory';
import { RepositoryFactory } from "../../repository/RepositoryFactory";
var properties = RepositoryFactory.get("properties");

export const setAdminLoading = (val) => async (dispatch) => {
  dispatch({ type: "ADMIN_LOADING", payload: val });
};
export const getAdminLoading = (val) => async (dispatch) => {
  dispatch({ type: "GET_ADMIN_LOADING", payload: val });
};

export const getProperties = (payload) => async (dispatch) => {
  try {
    // await dispatch(getAdminLoading(true));
    let { data } = await properties.getListProjects(payload);
    // console.log('data', data);

    if (data) {
      await dispatch({
        type: "GET_PROPERTIES",
        payload: data.rows,
      });
    }
    // dispatch(getAdminLoading(false));
  } catch (e) {
    // if (e.response?.status == 401) {
    //   localStorage.clear();
    //   dispatch({ type: "LOGOUT_SUCCESS" });
    // } else {
    //   alert(e.response.data);
    // }
    // dispatch(getAdminLoading(false));
    // alert(e.response.data.message);
  }
};
export const getPropertyDetails = (payload) => async (dispatch) => {
  try {
    // await dispatch(getAdminLoading(true));
    let { data } = await properties.getPropertyDetails(payload);

    if (data) {
      await dispatch({
        type: "GET_PROPERTY_DETAILS",
        payload: data,
      });
    }
    // dispatch(getAdminLoading(false));
  } catch (e) {
    // dispatch(getAdminLoading(false));
    // alert(e.response.data.message);
  }
};
