import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import NavBar from '../components/NavBar';
import SideBar from '../components/SideBar';
import routes from '../routes';

const Main = props => {
	const history = useHistory();
	const { token } = useSelector(state => state.authUser);
	if (!token) {
		history.push('/');
	}
	return (
		<>
			{/* <!-- main-wrap --> */}
			<div className='main-wrap'>
				<main>
					{/* admin-section */}
					<section className='admin-section'>
						<div className='admin-section-inner'>
							<SideBar routes={routes}></SideBar>
							{/* <!-- admin-main --> */}
							<div className='admin-main'>
								<NavBar />
								<div className='admin-main-inner'>
									{props.children}
								</div>
							</div>
						</div>
					</section>
					{/* <!-- admin-section-end --> */}
				</main>
				{/* <!-- main end --> */}
			</div>
			{/* <!-- main-wrap --> */}
		</>
	);
};

export default Main;
