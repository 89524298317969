import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Form, FormFeedback, Input, Spinner } from "reactstrap";
import { invalid, moment } from "moment";

import {
  getAccountDetailsAct,
  getAddress,
  getPanDetails,
  updateAddress,
  updatePanDetails,
  updateAccountDetails,
  getNomineeDetailsAct,
  updateNomineeDetails,
  getPersonalDetailsAct,
  updatePersonalDetails,
  getAllBankAccounts,
  getListNomineeDetailsAct,
  addNomineeDetails,
  addAccountDetails,
  addAddress,
} from "../../store/actions/accountAction";

function AccountForm() {
  const [accountId, setAccountId] = useState("");
  const [inputClass, setInputClass] = useState(false);
  // console.log("inputClass", inputClass);
  // console.log("accountId", accountId);
  const [nomineeId, setNomineeId] = useState("");
  // console.log("nomineeId", nomineeId);
  const [line1, setLine1] = useState("");
  const [line2, setLine2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [pin, setPin] = useState("");
  const [panNumber, setPanNumber] = useState("");
  // console.log('panImage', panImage);
  const [img, setImg] = useState("");
  const dispatch = useDispatch();
  const [fullName, setFullName] = useState("");
  // console.log("name", fullName);
  const [dob, setDob] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [bankName, setBankName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [nominee, setNominee] = useState("");
  const [relationship, setRelationship] = useState("");
  const [gender, setGender] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const id = "8cbac353-29e0-4e84-a38b-1d3d2a863083";
  const panId = "78636c01-53aa-4e80-b0f4-64ff50cb9427";
  const clientId = "ca103d8b-1306-46ea-803b-f95c39329127";
  function maxLengthCheck(object) {
    // console.log("object ", object.value);
    // object.value = object.value
    //   .replace(/[^0-9.]/g, "")
    //   .replace(/(\..*)\./g, "$1");
    if (object.value.length == 1 && !(object.value.indexOf("+") > -1))
      object.value = "+" + object.value;
    if (object.value.length > 13) object.value = object.value.slice(0, 13);
  }
  const history = useHistory();
  const { token } = useSelector((state) => state.authUser);
  const [addressMsg, setAddressMsg] = useState("");
  const [panMsg, setPanMsg] = useState("");
  const [bankMsg, setBankMsg] = useState("");
  const [NomineeMsg, setNomineeMsg] = useState("");
  const [personalMsg, setPersonalMsg] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [disabled1, setDisabled1] = useState(true);
  const [disabled2, setDisabled2] = useState(true);
  const [disabled3, setDisabled3] = useState(true);
  const [disabled4, setDisabled4] = useState(true);
  const {
    address,
    panDetails,
    account,
    nomineeDetails,
    loading,
    panLoading,
    bankLoading,
    nomineeLoading,
    personalDetails,
    userLoading,
    detailsLoading,
    allBankAccounts,
    nomineeListDetails,
    successMessage,
    errorMessage,
  } = useSelector((state) => state.account);
  // console.log("nomineeListDetails", nomineeListDetails);
  // console.log("nomineeDetails", nomineeDetails);

  useEffect(() => {
    setNominee(nomineeDetails?.fullName ? nomineeDetails?.fullName : "");
    setRelationship(
      nomineeDetails?.relationship ? nomineeDetails?.relationship : ""
    );
  }, [nomineeDetails]);

  useEffect(() => {
    // console.log("nominee details", nomineeListDetails);
    if (nomineeListDetails?.length > 0) {
      nomineeListDetails?.forEach((el) => {
        setNomineeId(nomineeListDetails[0]?.id);
      });
    }
  }, [nomineeListDetails]);
  // console.log('address', address);
  useEffect(() => {
    setName(account?.accountHolderName);
    setBankName(account?.bankName);
    setAccountNumber(account?.accountNumber);
    setIfscCode(account?.ifscCode);
  }, [account]);
  useEffect(() => {
    dispatch(getAddress(id));
    dispatch(getPanDetails());
    dispatch(getAccountDetailsAct(accountId));
    dispatch(getNomineeDetailsAct(nomineeId));
    dispatch(getListNomineeDetailsAct());
    dispatch(getPersonalDetailsAct());
    dispatch(getAllBankAccounts());
  }, []);
  useEffect(() => {
    setFullName(personalDetails?.name ? personalDetails?.name : "");
    setEmail(personalDetails?.email ? personalDetails?.email : "");
    setPhone(personalDetails?.mobile ? personalDetails?.mobile : "");
    setDob(personalDetails?.dob ? personalDetails?.dob : "");
    setGender(personalDetails?.gender ? personalDetails?.dob : "");
  }, [personalDetails]);
  // console.log("addres", address);

  useEffect(() => {
    setLine1(address?.line1 ? address?.line1 : "");
    setLine2(address?.line2 ? address?.line2 : "");
    setCity(address?.city ? address?.city : "");
    setState(address?.state ? address?.state : "");
    setCountry(address?.country ? address?.country : "");
    setPin(address?.pin ? address?.pin : "");
  }, [address]);

  useEffect(() => {
    if (Object.keys(panDetails).length) {
      setPanNumber(panDetails?.number);
    }
  }, [panDetails]);
  useEffect(() => {
    if (allBankAccounts.length > 0) {
      allBankAccounts?.forEach((el) => {
        setAccountId(el?.id);
      });
    }
  }, [allBankAccounts]);

  const handleDate = (e) => {
    const value = e.target.value;
    if (value == personalDetails?.date) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
    value.replace(/\D/g, "").substring(0, 10);
    // Divide numbers in 3 parts :"(123) 456-7890"
    const first = value.substring(0, 4);
    const middle = value.substring(5, 7);
    const last = value.substring(8, 10);

    if (value.length > 6) {
      setDob(`${first}-${middle}-${last}`);
    } else if (value.length > 4) {
      setDob(`${first}-${middle}`);
    } else if (value.length >= 0) {
      setDob(value);
    }
  };

  const [isValidating, setIsValidating] = useState({
    isValidPanNumber: false,
    isValidFullName: false,
    isValidPhoneNo: false,
    isValidAddressLine1: false,
    isValidCity: false,
    isValidState: false,
    isValidCountry: false,
    isValidPin: false,
    isValidNominee: false,
    isValidRelationship: false,
  });

  const handleFormValidationChange = (key, val) => {
    setIsValidating((prevState) => ({ ...prevState, [key]: val }));
  };

  const [successVisible, setSuccessVisible] = useState(false);
  const [errorVisible, setErrorVisible] = useState(false);

  const [successPan, setSuccessPan] = useState(false);

  const [successAddress, setSuccessAddress] = useState(false);

  const [successBank, setSuccessBank] = useState(false);

  const [successNominee, setSuccessNominee] = useState(false);

  const [isPersonalSelected, setIsPersonalSelected] = useState(false);
  const [isPanSelected, setIsPanSelected] = useState(false);
  const [panImage, setPanImage] = useState(null);
  const [personalImage, setPersonalImage] = useState(null);
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [able, setAbled] = useState(false);
  const changeHandler = (event) => {
    setPersonalImage(event.target.files[0]);
    if (event.target.files[0] == personalDetails?.profilePicDownloadUrl) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
    setIsPersonalSelected(true);
  };
  const changePanImage = (e) => {
    setPanImage(e.target.files[0]);
    if (e.target.value == panDetails?.downloadUrl) {
      setDisabled2(true);
    } else {
      setDisabled2(false);
    }
    setIsPanSelected(true);
  };

  // console.log("address", address, account);

  return (
    <>
      <div className="account-box">
        {/* <!-- account-c-group --> */}
        <div className="account-c-group">
          <a
            href="#"
            className="acc-grp-title"
            data-bs-toggle="collapse"
            data-bs-target="#acc-grp1"
          >
            <div className="f-icon">
              <img src={require("../../assets/icon/user.svg")} alt="" />
            </div>
            <h3>Personal Information</h3>
            <span className="ind-icon">
              <i className="fa fa-chevron-down text-danger"></i>
            </span>
          </a>
          <div className="acc-grp-m-content">
            <div className="acc-grp-m-content-inner collapse" id="acc-grp1">
              <div className="text-left mb-2">
                {successVisible ? (
                  <span className="text-success fw-bold">{personalMsg}</span>
                ) : (
                  ""
                )}
                {errorVisible ? (
                  <span className="text-danger fw-bold">{personalMsg}</span>
                ) : (
                  ""
                )}
              </div>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
					// console.log("personal details", personalDetails);
                  if (fullName && phone) {
                    let tempData = {};

                    if (fullName != personalDetails?.name  && fullName!='') {
                      tempData = {
                        ...tempData,
                        name: fullName,
                      };
                    }
                    if (email != personalDetails?.email  && email !='') {
                      tempData = {
                        ...tempData,
                        email: email,
                      };
                    }
                    if (phone != personalDetails?.mobile  && phone !='') {
                      tempData = {
                        ...tempData,
                        mobile: phone,
                      };
                    }
                    if (dob != personalDetails?.dob  && dob !='') {
                      tempData = {
                        ...tempData,
                        dob: dob,
                      };
                    }
                    dispatch(
                      updatePersonalDetails(
                        tempData,
                        (res) => {
                          if (res && res == "success") {
                            setSuccessVisible(true);
                            setPersonalMsg(
                              "Updated Personal details"
                            );
                            setTimeout(() => {
                              setSuccessVisible(false);
                              setPersonalMsg("");
                            }, 3000);
                            setPersonalImage(null);
                            setIsPersonalSelected(false);
                            dispatch(getPersonalDetailsAct());
                            setDisabled(true);
                          } else if (res && res == "fail") {
                            setErrorVisible(true);
                            setPersonalMsg(
                              "Server cant reached! Error Occured"
                            );
                            setTimeout(() => {
                              setErrorVisible(false);
                              setPersonalMsg("");
                            }, 3000);
                            setPersonalImage(null);
                            setIsPersonalSelected(false);
                            dispatch(getPersonalDetailsAct());
                            setDisabled(true);
                          }
                        },
                        personalImage
                        // () => {
                        //   setPersonalImage(null);
                        //   setIsPersonalSelected(false);
                        //   dispatch(getPersonalDetailsAct());
                        // }
                      )
                    );
                  } else {
                    if (fullName == "") {
                      handleFormValidationChange("isValidFullName", true);
                    }
                    if (phone == "") {
                      handleFormValidationChange("isValidPhoneNo", true);
                    }
                  }
                }}
              >
                <div className="acc-grp-form-row">
                  <div className="column">
                    <div className="single-input-x">
                      <label className="label-x">Full name*</label>
                      <div className="input-x-outer">
                        <Input
                          type="text"
                          invalid={isValidating.isValidFullName}
                          className="input-x"
                          value={fullName}
                          onChange={(e) => {
                            setFullName(e.target.value);
                            if (e.target.value == personalDetails?.name) {
                              setDisabled(true);
                            } else {
                              setDisabled(false);
                            }
                            handleFormValidationChange(
                              "isValidFullName",
                              false
                            );
                          }}
                        />
                        <FormFeedback>Please fill out this field</FormFeedback>
                      </div>
                    </div>
                  </div>
                  <div className="column">
                    <div className="single-input-x">
                      <label className="label-x">Date of brith</label>
                      <div className="input-x-outer">
                        <Input
                          type="text"
                          className="input-x"
                          // required
                          placeholder="yyyy-mm-dd"
                          value={dob}
                          // moment(new Date(e.target.value)).format('YYYY-MM-DD');
                          onChange={handleDate}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="column">
                    <div className="single-input-x">
                      <label className="label-x">Email</label>
                      <div className="input-x-outer">
                        <Input
                          type="email"
                          className="input-x"
                          //   required={email?.length !== 0}
                          //   oninvalid="this.setCustomValidity('Enter User Name Here')"
                          //   oninput="this.setCustomValidity('')"
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                            if (e.target.value == personalDetails?.email) {
                              setDisabled(true);
                            } else {
                              setDisabled(false);
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div className='column column-dbl-row'>
										<div className='single-input-x'>
											<label className='label-x'>
												Profile image*
											</label>
											<div className='acc-user-img'>
												<img
													src={require('../../assets/img/user-profile-img.png')}
													alt='profile-img'
												/>
											</div>
										</div>
									</div> */}
                  <div className="column">
                    <div className="single-input-x">
                      <label className="label-x">Phone Number*</label>
                      <div className="input-x-outer">
                        <Input
                          type="text"
                          className="input-x"
                          invalid={isValidating.isValidPhoneNo}
                          //   pattern="[0-9]+"
                          onInput={(e) => maxLengthCheck(e.target)}
                          // required
                          //   ={phone?.length != 0}
                          min="10000000000"
                          //   max="9999999999999"
                          value={phone}
                          onChange={(e) => {
                            setPhone(e.target.value);
                            handleFormValidationChange("isValidPhoneNo", false);
                            if (e.target.value == personalDetails?.mobile) {
                              setDisabled(true);
                            } else {
                              setDisabled(false);
                            }
                          }}
                        />
                        <FormFeedback>Please fill out this field</FormFeedback>
                      </div>
                    </div>
                  </div>
                  <div className="column">
                    <div className="single-input-x">
                      <label className="label-x">
                        <span className="d-sm-block d-none">&nbsp;</span>
                      </label>

                      {isPersonalSelected ? (
                        <div>
                          <p>{personalImage?.name}</p>
                        </div>
                      ) : (
                        <div
                          className="pl-2 text-center  border-dotted mb-3"
                          style={{
                            border: "2px dotted black",
                            height: "200px",
                            width: "50%",
                            margin: "auto",
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <div className="d-flex justify-content-center align-items-center" style={{fontSize: "14px"}}>
                            {personalDetails?.profilePicDownloadUrl ? (
                              <img
                                style={{
                                  height: "190px",
                                  width: "100%",
                                }}
                                src={personalDetails?.profilePicDownloadUrl}
                              />
                            ) : (
                              "You have not uploaded any picture yet."
                            )}
                          </div>
                        </div>
                      )}
                      <div className="input-x-outer">
                        <label
                          type="text"
                          className="input-x input-file pointer"
                        >
                          Click here to upload your picture
                          <Input
                            type="file"
                            className="d-none"
                            // required
                            // value={panImage}
                            onChange={changeHandler}
                          />
                        </label>
                      </div>
                    </div>

                    {/*{personalDetails && personalDetails?.profilePicDownloadUrl && (*/}
                    {/*  <div className="mt-2">*/}
                    {/*    <a*/}
                    {/*      href={personalDetails?.profilePicDownloadUrl}*/}
                    {/*      target="_blank"*/}
                    {/*    >*/}
                    {/*      Download Image*/}
                    {/*    </a>*/}
                    {/*  </div>*/}
                    {/*)}*/}
                  </div>
                </div>

                <div className="acc-form-bt-row d-flex justify-content-center">
                  <Button
                    type="submit"
                    className="default-button min-w default-button--theme2 mt-5"
                    disabled={disabled}
                  >
                    {detailsLoading ? <Spinner size="sm" /> : "Save"}
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>

        {/* <!-- account-c-group-end --> */}

        {/* <!-- account-c-group --> */}
        <div className="account-c-group">
          <a
            href="#"
            className="acc-grp-title"
            data-bs-toggle="collapse"
            data-bs-target="#acc-grp2"
          >
            <div className="f-icon">
              <img src={require("../../assets/icon/address-pin.svg")} alt="" />
            </div>
            <h3>Address</h3>
            <span className="ind-icon">
              <i className="fa fa-chevron-down text-danger"></i>
            </span>
          </a>

          <div className="acc-grp-m-content">
            <div className="acc-grp-m-content-inner collapse" id="acc-grp2">
              <div className="text-left mb-2">
                {successAddress ? (
                  <span className="text-success fw-bold">
                    {successMessage ? (
                      successMessage
                    ) : (
                      <h3 className="text-danger">{errorMessage}</h3>
                    )}
                  </span>
                ) : (
                  ""
                )}
              </div>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  if (line1 && city && state && country && pin) {
                    let tempData = {};

                    if (line1 != address?.line1) {
                      tempData = {
                        ...tempData,
                        line1: line1,
                      };
                    }
                    if (line2 != address?.line2) {
                      tempData = {
                        ...tempData,
                        line2: line2,
                      };
                    }
                    if (city != address?.city) {
                      tempData = {
                        ...tempData,
                        city: city,
                      };
                    }
                    if (state != address?.state) {
                      tempData = {
                        ...tempData,
                        state: state,
                      };
                    }
                    if (country != address?.country) {
                      tempData = {
                        ...tempData,
                        country: country,
                      };
                    }
                    if (pin != address?.pin) {
                      tempData = {
                        ...tempData,
                        pin: pin,
                      };
                    }
                    // console.log("temps", tempData);
                    const data = {
                      line1: line1,
                      line2: line2,
                      city: city,
                      state: state,
                      country: country,
                      pin: pin,
                    };
                    let obj = { data: tempData };
                    let obj1 = { data: data };
                    // console.log("data", data);

                    if (address) {
                      dispatch(
                        updateAddress(id, obj, () => {
                          setSuccessAddress(true);

                          setTimeout(() => {
                            setSuccessAddress(false);
                          }, 3000);
                          setDisabled1(true);
                        })
                      );
                    } else {
                      dispatch(addAddress(obj));
                      setSuccessAddress(true);

                      setTimeout(() => {
                        setSuccessAddress(false);
                      }, 3000);
                      setDisabled1(true);

                      //dispatch(addAddress(obj1));
                      //setAddressMsg("Address added Successfully");
                    }
                  } else {
                    if (line1 == "") {
                      handleFormValidationChange("isValidAddressLine1", true);
                    }
                    if (city == "") {
                      handleFormValidationChange("isValidCity", true);
                    }
                    if (state == "") {
                      handleFormValidationChange("isValidState", true);
                    }
                    if (country == "") {
                      handleFormValidationChange("isValidCountry", true);
                    }
                    if (pin == "") {
                      handleFormValidationChange("isValidPin", true);
                    }
                  }
                }}
              >
                <div className="acc-grp-form-row">
                  {/* <div className="column">
                    <div className="single-input-x">
                      <label className="label-x">Hno</label>
                      <div className="input-x-outer">
                        <input
                          type="text"
                          className="input-x"
                          value="5"
                          required
                        />
                      </div>
                    </div>
                  </div> */}
                  <div className="column">
                    <div className="single-input-x">
                      <label className="label-x">Address line 1*</label>
                      <div className="input-x-outer">
                        <Input
                          type="text"
                          className="input-x"
                          // required
                          invalid={isValidating.isValidAddressLine1}
                          value={line1}
                          onChange={(e) => {
                            setLine1(e.target.value);
                            if (e.target.value == address?.line1) {
                              setDisabled1(true);
                            } else {
                              setDisabled1(false);
                            }
                            handleFormValidationChange(
                              "isValidAddressLine1",
                              false
                            );
                          }}
                        />
                        <FormFeedback>Please fill out this field</FormFeedback>
                      </div>
                    </div>
                  </div>
                  <div className="column">
                    <div className="single-input-x">
                      <label className="label-x">Address line 2</label>
                      <div className="input-x-outer">
                        <Input
                          type="text"
                          className="input-x"
                          value={line2}
                          onChange={(e) => {
                            setLine2(e.target.value);
                            if (e.target.value == address?.line2) {
                              setDisabled1(true);
                            } else {
                              setDisabled1(false);
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="column">
                    <div className="single-input-x">
                      <label className="label-x">City*</label>
                      <div className="input-x-outer">
                        <Input
                          type="text"
                          className="input-x"
                          invalid={isValidating.isValidCity}
                          // required
                          value={city}
                          onChange={(e) => {
                            setCity(e.target.value);
                            if (e.target.value == address?.city) {
                              setDisabled1(true);
                            } else {
                              setDisabled1(false);
                            }
                            handleFormValidationChange("isValidCity", false);
                          }}
                        />
                        <FormFeedback>Please fill out this field</FormFeedback>
                      </div>
                    </div>
                  </div>
                  <div className="column">
                    <div className="single-input-x">
                      <label className="label-x">State*</label>
                      <div className="input-x-outer">
                        <Input
                          type="text"
                          className="input-x"
                          invalid={isValidating.isValidState}
                          // required
                          value={state}
                          onChange={(e) => {
                            setState(e.target.value);
                            if (e.target.value == address?.state) {
                              setDisabled1(true);
                            } else {
                              setDisabled1(false);
                            }
                            handleFormValidationChange("isValidState", false);
                          }}
                        />
                        <FormFeedback>Please fill out this field</FormFeedback>
                      </div>
                    </div>
                  </div>
                  <div className="column">
                    <div className="single-input-x">
                      <label className="label-x">Country*</label>
                      <div className="input-x-outer">
                        <Input
                          type="text"
                          className="input-x"
                          invalid={isValidating.isValidCountry}
                          // required
                          value={country}
                          onChange={(e) => {
                            setCountry(e.target.value);
                            if (e.target.value == address?.country) {
                              setDisabled1(true);
                            } else {
                              setDisabled1(false);
                            }
                            handleFormValidationChange("isValidCountry", false);
                          }}
                        />
                        <FormFeedback>Please fill out this field</FormFeedback>
                      </div>
                    </div>
                  </div>
                  <div className="column">
                    <div className="single-input-x">
                      <label className="label-x">PIN code*</label>
                      <div className="input-x-outer">
                        <Input
                          type="text"
                          className="input-x"
                          invalid={isValidating.isValidPin}
                          // required
                          value={pin}
                          onChange={(e) => {
                            setPin(e.target.value);
                            if (e.target.value == address?.pin) {
                              setDisabled1(true);
                            } else {
                              setDisabled1(false);
                            }
                            handleFormValidationChange("isValidPin", false);
                          }}
                        />
                        <FormFeedback>Please fill out this field</FormFeedback>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="acc-form-bt-row d-flex justify-content-center">
                  <Button
                    className="default-button min-w default-button--theme2 mt-5"
                    type="submit"
                    disabled={disabled1}
                  >
                    {loading ? <Spinner size="sm" /> : "Save"}
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>

        {/* <!-- account-c-group-end --> */}

        {/* <!-- account-c-group --> */}
        <div className="account-c-group">
          <a
            href="#"
            className="acc-grp-title"
            data-bs-toggle="collapse"
            data-bs-target="#acc-grp3"
          >
            <div className="f-icon">
              <img
                src={require("../../assets/icon/pan-card-icon.svg")}
                alt=""
              />
            </div>
            <h3>PAN Details</h3>
            <span className="ind-icon">
              <i className="fa fa-chevron-down text-danger"></i>
            </span>
          </a>
          <div className="acc-grp-m-content">
            <div className="acc-grp-m-content-inner collapse" id="acc-grp3">
              <div className="text-left mb-2 ">
                {successPan ? (
                  <span className="text-success fw-bold">
                    {successMessage ? (
                      successMessage
                    ) : (
                      <h3 className="text-danger">{errorMessage}</h3>
                    )}
                  </span>
                ) : (
                  ""
                )}
              </div>
              <Form
                action="#"
                onSubmit={(e) => {
                  e.preventDefault();
                  if (panNumber == "") {
                    handleFormValidationChange("isValidPanNumber", true);
                  } else {
                    if (Object.keys(panDetails).length > 0) {
                      dispatch(
                        updatePanDetails(
                          {
                            panNumber,
                            panImage,
                            type: "update",
                          },
                          () => {
                            setSuccessPan(true);
                            setTimeout(() => {
                              setSuccessPan(false);
                            }, 3000);
                            setPanImage(null);
                            dispatch(getPanDetails());
                            setIsPanSelected(false);
                            setDisabled2(true);
                          }
                          // () => {
                          //   setPanImage(null);
                          //   dispatch(getPanDetails());
                          //   setIsPanSelected(false);
                          // }
                        )
                      );
                    } else {
                      dispatch(
                        updatePanDetails(
                          {
                            panNumber,
                            panImage,
                            type: "add",
                          },
                          () => {
                            setSuccessPan(true);
                            setTimeout(() => {
                              setSuccessPan(false);
                            }, 3000);
                            setPanImage(null);
                            dispatch(getPanDetails());
                            setIsPanSelected(false);
                            setDisabled2(true);
                          }
                          // () => {
                          //   setPanImage(null);
                          //   dispatch(getPanDetails());
                          //   setIsPanSelected(false);
                          // }
                        )
                      );
                    }
                  }
                }}
              >
                <div className="acc-grp-form-row">
                  <div className="column">
                    <div className="single-input-x">
                      <label className="label-x">PAN Number</label>
                      <div className="input-x-outer">
                        <Input
                          type="text"
                          className="input-x"
                          // required
                          invalid={isValidating.isValidPanNumber}
                          value={panNumber}
                          onChange={(e) => {
                            setPanNumber(e.target.value);
                            if (e.target.value == panDetails?.panNumber) {
                              setDisabled2(true);
                            } else {
                              setDisabled2(false);
                            }
                            handleFormValidationChange(
                              "isValidPanNumber",
                              false
                            );
                          }}
                        />
                        <FormFeedback>Please fill out this field</FormFeedback>
                      </div>
                    </div>
                  </div>
                  <div className="column">
                    <div className="single-input-x">
                      <label className="label-x">
                        <span className="d-sm-block d-none">&nbsp;</span>
                      </label>

                      <div className="input-x-outer">
                        <label
                          type="text"
                          className="input-x input-file pointer"
                        >
                          Upload PAN image
                          <Input
                            type="file"
                            className="d-none"
                            // required
                            // value={panImage}
                            onChange={changePanImage}
                          />
                        </label>
                      </div>
                      {isPanSelected ? (
                        <div>{<p>Filename: {panImage?.name}</p>}</div>
                      ) : (
                        <div
                          className="pl-2 text-center border-dotted mt-3"
                          style={{
                            border: "2px dotted black",
                            height: "200px",
                            width: "50%",
                            margin: "auto",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <div className="d-flex justify-content-center align-items-center">
                            {panDetails?.downloadUrl ? (
                              <img
                                src={panDetails?.downloadUrl}
                                style={{
                                  height: "190px",
                                }}
                              />
                            ) : (
                              "You have not uploaded the PAN Card picture yet."
                            )}
                          </div>
                        </div>
                      )}
                    </div>

                    {/*{panDetails && panDetails?.downloadUrl && (*/}
                    {/*  <div className="mt-2">*/}
                    {/*    <a href={panDetails?.downloadUrl} target="_blank">*/}
                    {/*      Download Image*/}
                    {/*    </a>*/}
                    {/*  </div>*/}
                    {/*)}*/}
                  </div>
                </div>

                <div className="acc-form-bt-row d-flex justify-content-center">
                  <Button
                    className="default-button min-w default-button--theme2 mt-5"
                    type="submit"
                    disabled={disabled2}
                  >
                    {panLoading ? <Spinner size="sm" /> : "Save"}
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>

        {/* <!-- account-c-group-end --> */}

        {/* <!-- account-c-group --> */}
        <div className="account-c-group">
          <a
            href="#"
            className="acc-grp-title"
            data-bs-toggle="collapse"
            data-bs-target="#acc-grp4"
          >
            <div className="f-icon">
              <img src={require("../../assets/icon/bank.svg")} alt="" />
            </div>
            <h3>Bank Details</h3>
            <span className="ind-icon">
              <i className="fa fa-chevron-down text-danger"></i>
            </span>
          </a>
          <div className="acc-grp-m-content">
            <div className="acc-grp-m-content-inner collapse" id="acc-grp4">
              <div className="text-left mb-2">
                {successBank ? (
                  <span className="text-success fw-bold">
                    {successMessage ? (
                      successMessage
                    ) : (
                      <h3 className="text-danger">{errorMessage}</h3>
                    )}
                  </span>
                ) : (
                  ""
                )}
              </div>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  let tempData = {};

                  if (name != account?.accountHolderName) {
                    tempData = {
                      ...tempData,
                      accountHolderName: name,
                    };
                  }
                  if (bankName != account?.bankName) {
                    tempData = {
                      ...tempData,
                      bankName: bankName,
                    };
                  }
                  if (accountNumber != account?.accountNumber) {
                    tempData = {
                      ...tempData,
                      accountNumber: accountNumber,
                    };
                  }
                  if (ifscCode != account?.ifscCode) {
                    tempData = {
                      ...tempData,
                      ifscCode: ifscCode,
                    };
                  }

                  const data = {
                    accountHolderName: name,
                    bankName: bankName,
                    accountNumber: accountNumber,
                    ifscCode: ifscCode,
                  };
                  // console.log("tempData", tempData);
                  let obj = { data: tempData };
                  let obj1 = { data: data };
                  if (account) {
                    dispatch(
                      updateAccountDetails(accountId, obj, () => {
                        setSuccessBank(true);
                        setTimeout(() => {
                          setSuccessBank(false);
                        }, 3000);
                        setDisabled3(true);
                      })
                    );
                  } else {
                    dispatch(
                      addAccountDetails(
                        obj,
                        () => {
                          setSuccessBank(true);
                          setTimeout(() => {
                            setSuccessBank(false);
                          }, 3000);
                          setDisabled3(true);
                          dispatch(getAccountDetailsAct(accountId));
                          dispatch(getAllBankAccounts());
                        }
                        // () => {
                        //   dispatch(getAccountDetailsAct(accountId));
                        //   dispatch(getAllBankAccounts());
                        // }
                      )
                    );
                  }
                }}
              >
                <div className="acc-grp-form-row">
                  <div className="column">
                    <div className="single-input-x">
                      <label className="label-x">Account Holder Name</label>
                      <div className="input-x-outer">
                        <Input
                          type="text"
                          className="input-x"
                          value={name}
                          onChange={(e) => {
                            setName(e.target.value);
                            if (e.target.value == account?.name) {
                              setDisabled3(true);
                            } else {
                              setDisabled3(false);
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="column">
                    <div className="single-input-x">
                      <label className="label-x">Bank Name</label>
                      <div className="input-x-outer">
                        <Input
                          type="text"
                          className="input-x"
                          value={bankName}
                          onChange={(e) => {
                            setBankName(e.target.value);
                            if (e.target.value == account?.bankName) {
                              setDisabled3(true);
                            } else {
                              setDisabled3(false);
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="column">
                    <div className="single-input-x">
                      <label className="label-x">Account Number</label>
                      <div className="input-x-outer">
                        <Input
                          type="text"
                          className="input-x"
                          value={accountNumber}
                          onChange={(e) => {
                            setAccountNumber(e.target.value);
                            if (e.target.value == account?.accountNumber) {
                              setDisabled3(true);
                            } else {
                              setDisabled3(false);
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="column">
                    <div className="single-input-x">
                      <label className="label-x">IFSC code</label>
                      <div className="input-x-outer">
                        <Input
                          type="text"
                          className="input-x"
                          value={ifscCode}
                          onChange={(e) => {
                            setIfscCode(e.target.value);
                            if (e.target.value == account?.ifscCode) {
                              setDisabled3(true);
                            } else {
                              setDisabled3(false);
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <br />

                <div className="acc-form-bt-row d-flex justify-content-center mt-5">
                  <Button
                    type="submit"
                    className="default-button min-w default-button--theme2"
                    disabled={disabled3}
                  >
                    {bankLoading ? <Spinner size="sm" /> : "Save"}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* <!-- account-c-group-end --> */}

        {/* <!-- account-c-group --> */}
        <div className="account-c-group">
          <a
            href="#"
            className="acc-grp-title"
            data-bs-toggle="collapse"
            data-bs-target="#acc-grp5"
          >
            <div className="f-icon">
              <img src={require("../../assets/icon/nominnee-eye.svg")} alt="" />
            </div>
            <h3>Nominee Details</h3>
            <span className="ind-icon">
              <i className="fa fa-chevron-down text-danger"></i>
            </span>
          </a>
          <div className="acc-grp-m-content">
            <div className="acc-grp-m-content-inner collapse" id="acc-grp5">
              <div className="text-left mb-2">
                {successNominee ? (
                  <span className="text-success fw-bold">
                    {successMessage ? (
                      successMessage
                    ) : (
                      <h3 className="text-danger">{errorMessage}</h3>
                    )}
                  </span>
                ) : (
                  ""
                )}
              </div>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  if (nominee && relationship) {
                    let tempData = {};
                    if (nominee != nomineeDetails?.fullName) {
                      tempData = {
                        ...tempData,
                        fullName: nominee,
                      };
                    }
                    if (relationship != nomineeDetails?.relationship) {
                      tempData = {
                        ...tempData,
                        relationship,
                      };
                    }
                    // console.log("tempDAta", tempData);

                    const data = {
                      fullName: nominee,
                      relationship: relationship,
                      clientId: nomineeId,
                    };
                    let obj = { data: tempData };
                    let obj1 = { data: data };
                    if (nomineeDetails) {
                      dispatch(
                        updateNomineeDetails(
                          nomineeId,
                          obj,
                          () => {
                            setSuccessNominee(true);
                            setTimeout(() => {
                              setSuccessNominee(false);
                            }, 3000);
                            dispatch(getNomineeDetailsAct(nomineeId));
                            dispatch(getListNomineeDetailsAct());
                            setDisabled4(true);
                          }
                          // () => {
                          //   dispatch(getNomineeDetailsAct(nomineeId));
                          //   dispatch(getListNomineeDetailsAct());
                          // }
                        )
                      );
                    } else {
                      dispatch(
                        addNomineeDetails(
                          obj,
                          () => {
                            setSuccessNominee(true);
                            setTimeout(() => {
                              setSuccessNominee(false);
                            }, 3000);
                            setDisabled4(true);
                            dispatch(getNomineeDetailsAct(nomineeId));
                            dispatch(getListNomineeDetailsAct());
                            setDisabled4(true);
                          }
                          // () => {
                          //   dispatch(getNomineeDetailsAct(nomineeId));
                          //   dispatch(getListNomineeDetailsAct());
                          // }
                        )
                      );

                      // dispatch(addNomineeDetails(obj1));
                      //setNomineeMsg("Nominee added successfully");
                    }
                  } else {
                    if (nominee == "") {
                      handleFormValidationChange("isValidNominee", true);
                    }
                    if (relationship == "") {
                      handleFormValidationChange("isValidRelationship", true);
                    }
                  }
                }}
              >
                <div className="acc-grp-form-row">
                  <div className="column">
                    <div className="single-input-x">
                      <label className="label-x">Nominee Full Name*</label>
                      <div className="input-x-outer">
                        <Input
                          type="text"
                          className="input-x"
                          invalid={isValidating.isValidNominee}
                          value={nominee}
                          onChange={(e) => {
                            setNominee(e.target.value);
                            if (e.target.value == nomineeDetails?.fullName) {
                              setDisabled4(true);
                            } else {
                              setDisabled4(false);
                            }
                            handleFormValidationChange("isValidNominee", false);
                          }}

                          // required
                        />
                        <FormFeedback>Please fill out this field</FormFeedback>
                      </div>
                    </div>
                  </div>
                  <div className="column">
                    <div className="single-input-x">
                      <label className="label-x">Relationship*</label>
                      <div className="input-x-outer">
                        <Input
                          type="text"
                          className="input-x"
                          value={relationship}
                          invalid={isValidating.isValidRelationship}
                          // required
                          onChange={(e) => {
                            setRelationship(e.target.value);
                            if (e.target.value == nomineeDetails?.relationship) {
                              setDisabled4(true);
                            } else {
                              setDisabled4(false);
                            }
                            handleFormValidationChange(
                              "isValidRelationship",
                              false
                            );
                          }}
                        />
                        <FormFeedback>Please fill out this field</FormFeedback>
                      </div>
                    </div>
                  </div>
                </div>
                <br />

                <div className="acc-form-bt-row d-flex justify-content-center">
                  <Button
                    type="submit"
                    className="default-button min-w default-button--theme2 mt-5"
                    disabled={disabled4}
                  >
                    {nomineeLoading ? <Spinner size="sm" /> : "Save"}
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
        {/* <!-- account-c-group-end --> */}
      </div>
    </>
  );
}

export default AccountForm;
