import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import InvestmentCard from "../components/myInvestment/InvestmentCard";
import InvestmentPagination from "../components/myInvestment/InvestmentPagination";
import {getInvestment} from "../store/actions/InvestmentAction";
import {Link} from "react-router-dom";

const MyInvestments = () => {
    const dispatch = useDispatch();
    const {investment} = useSelector((state) => state.investment);
    // console.log("investment", investment);
    useEffect(() => {
        dispatch(getInvestment());
    }, []);
    //   const data = [
    //     {
    //       img: require("../assets/icon/location-theme2.svg"),
    //       title: "Premium Apartments on heelers road",
    //       address: "Sathanur, Bangalore",
    //       targetROI: "40%",
    //       estimatedDuration: "12 Months",
    //       totalBudget: "7 Crores",
    //     },
    //     {
    //       img: require("../assets/icon/location-theme2.svg"),
    //       title: "Premium Apartments on heelers road",
    //       address: "Sathanur, Bangalore",
    //       targetROI: "40%",
    //       estimatedDuration: "12 Months",
    //       totalBudget: "7 Crores",
    //     },
    //   ];

    //   console.log("fdfd", data.targetROI);
    // console.log(investment)
    return (
        <>
            {/* <!-- page-title-mobile --> */}
            <div className="page-title-mobile d-xl-none">
                <h3 className="mb-0">My Investments</h3>
            </div>
            <div className="s-card-row s-card-linear-row">
                {
                    investment && investment.length > 0 ?
                        (investment.map((el, id) => {
                            return <InvestmentCard key={id} investment={el}/>;
                        })) : empty()
                }
                {/* <InvestmentPagination /> */
                }
            </div>
            {/* <!-- s-card-row-end --> */
            }
        </>
    );
};

const empty = () => {
    return (<div className="s-card">
        <div className="s-card-in-content">
            <div className="s-card-row-full">You don't have any investments yet. Please checkout our &nbsp;
                <Link className='color-theme1' to="/projects" target="_blank"> open projects
                    for investment.</Link>
            </div>
        </div>
    </div>)
}

export default MyInvestments;
