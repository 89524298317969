const initialData = {
  loading: false,
  otpLoading: false,
};

const authReducer = (state = initialData, action) => {
  // console.log('action', action.payload);
  switch (action.type) {
    case "LOGIN_LOADER":
      return {
        ...state,
        loading: action.payload,
      };
    case "OTP_LOADER":
      return {
        ...state,
        otpLoading: action.payload,
      };

    default:
      return state;
  }
};

export default authReducer;
