import AccountRepository from './AccountRepository';
import AuthRepository from './AuthRepository';
import CurrentUserRepository from './CurrentUserRepository';
import GetSupportRepository from './GetSupportRepository';
import InvestmentRepository from './InvestmentRepository';
import MessageRepository from './MessageRepository';
import PropertiesRepository from './PropertiesRepository';
import TransactionsRepository from './TransactionsRepository';

const repositories = {
	properties: PropertiesRepository,
	account: AccountRepository,
	message: MessageRepository,
	support: GetSupportRepository,
	transaction: TransactionsRepository,
	auth: AuthRepository,
	user: CurrentUserRepository,
	investment: InvestmentRepository,
};
// eslint-disable-next-line import/prefer-default-export
export const RepositoryFactory = {
	get: name => repositories[name],
};
