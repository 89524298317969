import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const Auth = (props) => {
  const history = useHistory();
  const { token } = useSelector((state) => state.authUser);
  if (token) {
    history.push("/dashboard");
  }
  // console.log('token', token);
  return <>{props.children}</>;
};

export default Auth;
