const initialData = {
	properties: [],
	propertyDetails: [],
};

const propertiesReducer = (state = initialData, action) => {
	// console.log('action', action.payload);
	switch (action.type) {
		case 'GET_PROPERTIES':
			return {
				...state,
				properties: action.payload,
			};
		case 'GET_PROPERTY_DETAILS':
			return {
				...state,
				propertyDetails: action.payload,
			};

		default:
			return state;
	}
};

export default propertiesReducer;
