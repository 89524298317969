import AccountForm from '../components/account/AccountForm';

const Account = () => {
	return (
		<>
			{/* <!-- page-title-mobile --> */}
			<div className='page-title-mobile d-xl-none'>
				<h3 className='mb-0'>Account</h3>
			</div>

			<AccountForm />
		</>
	);
};

export default Account;
