import share from '../../assets/img/properties/share.svg';
import loginImg from '../../assets/img/properties/log-in-01.svg';
import messageChat from '../../assets/img/properties/message-chat-circle.svg';
// import videoRecorder from '../../assets/img/properties/video-recorder.svg';

function OpportunityCard(props) {

    const openBrochureInNewTab = () => {
        if (props.data && props.data.id) {
            const brochureLink = `${process.env.REACT_APP_S3_PUBLIC_BUCKET_URL}/${props.data.id}/brochure.pdf`;
            window.open(brochureLink, '_blank', 'noopener,noreferrer');
        }
    };

    return (
        <div className='property-details-side-column'>
            <div className='property-details-side-column-inner'>
                <a href='/login' className='op-btn w-100 top-button'>
                    Invest Now
                </a>

                <div className='property-s-actions'>
                    <div className='psa-single'>
                        <a href={'whatsapp://send?text=' + window.location.href} className='icon'>
                            <img src={share} alt=''/>
                        </a>
                        <span className='txt'>Share Opportunity</span>
                    </div>
                    <div className='psa-single'
                         onClick={() => openBrochureInNewTab()}>
                        <a
                            className='icon'>
                            <img src={loginImg} alt=''/>
                        </a>
                        <span className='txt'>Get E-Brochure</span>
                    </div>

                    <div className='psa-single'>
                        <a href='https://wa.me/+917337823894?text=I%20am%20interested%20in%20this%20project%2C%20please%20tell%20me%20more.' className='icon'>
                            <img src={messageChat} alt=''/>
                        </a>
                        <span className='txt'>Live Chat Support</span>
                    </div>
                    {/*<div className='psa-single'>*/}
                    {/*    <a href='' className='icon'>*/}
                    {/*        <img src={videoRecorder} alt=''/>*/}
                    {/*    </a>*/}
                    {/*    <span className='txt'>Watch How it Works</span>*/}
                    {/*</div>*/}
                </div>
            </div>
        </div>
    );
}

export default OpportunityCard;
