import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Button, Spinner } from "reactstrap";
import flag from "../../assets/img/form/flag.png";
import { sendOtpAction } from "../../store/actions/authAction";

function LoginForm() {
  const [countryCode, setCountryCode] = useState("+91");
  const [successLogin, setSuccessLogin] = useState(false);

  // console.log(countryCode);
  const [phone, setPhone] = useState("");
  // console.log('phone', phone.length);
  localStorage.setItem("phone", countryCode + phone);
  const history = useHistory();
  const { loading } = useSelector((state) => state.auth);
  const { loginSuccessMessage } = useSelector((state) => state.authUser);

  function maxLengthCheck(object) {
    // console.log("object ", object.value);
    if (object.value.length > 10) object.value = object.value.slice(0, 10);
  }
  const [msg, setMsg] = useState("");
  const [color, setColor] = useState("");

  const dispatch = useDispatch();
  return (
    <>
      <div className="form-fill-side">
        <div className="form-fill-side-inner">
          <div className="form-sss">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                const data = {
                  mobile: countryCode + phone,
                };
                if (phone.length == 10) {
                  dispatch(
                    sendOtpAction(data, () => {
                      setSuccessLogin(true);

                      setTimeout(() => {
                        setSuccessLogin(false);
                        history.push("/otp");
                      }, 1000);
                    })
                  );

                  setColor("success");
                } else {
                  setMsg("Invalid phone number! Phone number has to be 10 digits long.");
                  setColor("danger");
                }
              }}
            >
              <div className="form-inner">
                <div className="form-title">
                  <div className="form-in-cont-p">
                    <h3 className="">Login</h3>
                    <p>
                      We will send you a one time password on this mobile number
                      for you to login!
                    </p>
                  </div>
                </div>

                <div className="form-main">
                  <div className="form-in-cont-p">
                    <div className="single-input-x">
                      <label htmlFor="" className="label-x">
                        Mobile Number*
                      </label>
                      <div className="input-x-outer input-x-n-outer">
                        <div className="country-select-ax">
                          <div className="flag">
                            <img src={flag} alt="" />
                          </div>
                          <select
                            value={countryCode}
                            onChange={(e) => setCountryCode(e.target.value)}
                          >
                            <option value="+91">+91</option>
                          </select>
                        </div>
                        <input
                          type="number"
                          className="input-x"
                          placeholder="type your number here..."
                          // minLength='10'
                          onInput={(e) => maxLengthCheck(e.target)}
                          maxLength="10"
                          name="phoneNumber"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </div>
                      {successLogin
                        ? loginSuccessMessage && (
                            <span className={`my-2 text-${color}`}>
                              {loginSuccessMessage}
                            </span>
                          )
                        : ""}
                    </div>
                    <div className="form-bottom">
                      <Button
                        className="op-btn submit-button w-100"
                        disabled={phone.length != 10 || loading}
                        // to='/otp'
                        type="submit"
                      >
                        {loading ? <Spinner size="sm" /> : "Send OTP"}
                      </Button>
                      {/* <p className="bt-sde">
                        <span>If you are visiting us first time, we will register an account for you with this number</span>
                      </p> */}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default LoginForm;
