import location from '../../assets/img/properties/location.svg';
import { prettyCurrency } from '../Utilities';

function ProjectDescriptionHead(props) {

	const projectDetails = props.data;
	return (
		<div className='property-details-title'>
			<div className='left'>
				<h2>{projectDetails?.title}</h2>
				<a href='#' className='location-x'>
					<div className='icon'>
						<img src={location} alt='' />
					</div>
					<span className='txt'>{projectDetails?.location}</span>
				</a>
			</div>
			<div className='right'>
				<h3>
					<span className='sm-txt d-lg-block d-none'>
						Total Budget:
					</span>
					<span className='r-txt'>{prettyCurrency(projectDetails?.budget)}</span>
				</h3>
				<p className='d-lg-block d-none mb-0'>
					Investment duration: {projectDetails?.durationMonths} Months
				</p>
			</div>
		</div>
	);
}

export default ProjectDescriptionHead;
