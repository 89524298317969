import {Link} from "react-router-dom";
import { prettyCurrency } from '../Utilities';

function InvestmentCard({investment}) {
    return (
        <>
            {investment ? (
                <div className="s-card">
                    <div className="s-card-in-content">
                        <div className="s-card-row-full">
                            <div className="text-side">
                                <h3 className="h3">{investment.project?.title}</h3>
                                <p className="location-text">
                                    {/* <img src={investment.img} alt="" className="im" /> */}
                                    <span className="txt">{investment.project?.location}</span>
                                </p>
                                <div className="s-card-states-wrap">
                                    <div className="sc-state-single">
                                        <h4>{investment.project?.roi}</h4>
                                        <p>Target ROI</p>
                                    </div>
                                    <div className="sc-state-single">
                                        <h4>{investment.project?.durationMonths} months</h4>
                                        <p>Estimated Duration</p>
                                    </div>
                                    <div className="sc-state-single">
                                        <h4>{prettyCurrency(investment.project?.budget)}</h4>
                                        <p>Total Budget</p>
                                    </div>
                                </div>
                                <div className="bt-button-row d-md-none">
                                    <Link
                                        to="/transactions"
                                        className="default-button min-w">
                                        View all transactions
                                    </Link>
                                </div>
                                <p className="bt-p">
                                    <span className="fw-600 color-theme1">Status:</span>{" "}
                                    {investment.project?.state}
                                </p>
                            </div>
                            <div className="image-side">
                                <div className="img-m">
                                    <img
                                        src={require("../../assets/img/new-opportunity.png")}
                                        alt=""
                                    />
                                </div>
                                <Link
                                    to="/transactions"
                                    className="default-button bt-button w-100 d-md-block d-none">
                                    View all transactions
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

            ) : (
                <>
                    <div className="table-box">
                        <div className="table-model table-model-bordered table-mdl-str2 d-md-block d-none">
                            <table>
                                <thead>
                                <tr>
                                    <th></th>
                                    <th>Name</th>
                                    <th>Invested Amount</th>
                                    <th>Project</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className="text-center" colSpan="6">
                                        There are no investments available
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="table-box-footer">
                            <div className="td-pagination">
                                <div className="pagination">
                                    <div className="left">
                                        <p className="mb-0">
                                            Total <span className="fw-600">0</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

export default InvestmentCard;
