const initialData = {
  address: [],
  panDetails: {},
  account: {},
  nomineeDetails: [],
  loading: false,
  panLoading: false,
  bankLoading: false,
  userLoading: false,
  nomineeLoading: false,
  personalDetails: [],
  allBankAccounts: {},
  nomineeListDetails: {},
  Message: "",
  errorMessage: "",
  successMsg: "",
  detailsLoading: false,
};

const accountReducer = (state = initialData, action) => {
  // console.log('action', action.payload);
  switch (action.type) {
    case "ADDRESS_LOADER":
      return {
        ...state,
        loading: action.payload,
      };
    case "PAN_LOADER":
      return {
        ...state,
        panLoading: action.payload,
      };
    case "BANK_LOADER":
      return {
        ...state,
        bankLoading: action.payload,
      };
    case "NOMINEE_LOADER":
      return {
        ...state,
        nomineeLoading: action.payload,
      };
    case "USER_LOADER":
      return {
        ...state,
        userLoading: action.payload,
      };
    case "USER_LOADER":
      return {
        ...state,
        userLoading: action.payload,
      };
    case "PERSONAL_DETAILS_LOADER":
      return {
        ...state,
        detailsLoading: action.payload,
      };
    case "GET_ADDRESS":
      return {
        ...state,
        address: action.payload,
      };
    case "GET_ACCOUNT_DETAILS":
      return {
        ...state,
        account: action.payload,
      };
    case "GET_ALL_BANK_ACCOUNTS":
      return {
        ...state,
        allBankAccounts: action.payload,
      };

    case "GET_PAN_DETAILS":
      return {
        ...state,
        panDetails: action.payload,
      };
    case "GET_NOMINEE_DETAILS":
      return {
        ...state,
        nomineeDetails: action.payload,
      };
    case "GET_LIST_NOMINEE_DETAILS":
      return {
        ...state,
        nomineeListDetails: action.payload,
      };
    case "PERSONAL_DETAILS":
      return {
        ...state,
        personalDetails: action.payload,
      };
    case "PERSONAL_SUCCESS":
      return {
        successMessage: action.payload,
        ...state,
        errorMessage: "",
      };
    case "PERSONAL_FAILED":
      return {
        ...state,
        errorMessage: action.payload,
        successMessage: "",
      };
    case "ADDRESS_SUCCESS":
      return {
        ...state,
        errorMessage: "",
        successMessage: action.payload,
      };
    case "ADDRESS_FAILED":
      return {
        ...state,
        errorMessage: "Failed updating Address!",
        successMessage: "",
      };
    case "PAN_SUCCESS":
      return {
        ...state,
        errorMessage: "",
        successMessage: action.payload,
      };
    case "PAN_FAILED":
      return {
        ...state,
        errorMessage: action.payload,
        successMessage: "",
      };

    case "BANK_SUCCESS":
      return {
        ...state,
        errorMessage: "",
        successMessage: action.payload,
      };
    case "BANK_FAILED":
      return {
        ...state,
        errorMessage: action.payload,
        successMessage: "",
      };
    case "NOMINEE_SUCCESS":
      return {
        ...state,
        errorMessage: "",
        successMessage: action.payload,
      };
    case "NOMINEE_FAILED":
      return {
        ...state,
        errorMessage: action.payload,
        successMessage: "",
      };
    case "NOMINEE_ADD_SUCCESS": {
      return {
        ...state,
        errorMessage: "",
        successMessage: action.payload,
      };
    }
    case "NOMINEE_ADD_FAILED": {
      return {
        ...state,
        errorMessage: action.payload,
        successMessage: "",
      };
    }
    case "ACCOUNT_SUCCESS": {
      return {
        ...state,
        errorMessage: "",
        successMessage: action.payload,
      };
    }
    case "ADDRESS_ADDED_SUCCESS": {
      return {
        ...state,
        errorMessage: "",
        successMessage: action.payload,
      };
    }
    case "ACCOUNT_ADDED_SUCCESS": {
      return {
        ...state,
        errorMessage: "",
        successMessage: action.payload,
      };
    }
    case "BANK_ADDED_FAILED": {
      return {
        ...state,
        errorMessage: action.payload,
        successMessage: "",
      };
    }
    case "MESSAGE_SUCCESS": {
      return {
        ...state,
        errorMessage: "",
        successMessage: action.payload,
      };
    }

    default:
      return state;
  }
};

export default accountReducer;
