import GetSupportForm from '../components/getSupport/GetSupportForm';

const GetSupport = () => {
	return (
		<>
			{/* <!-- page-title-mobile --> */}
			<div className='page-title-mobile d-xl-none'>
				<h3 className='mb-0'>Get Support</h3>
			</div>

			<GetSupportForm />
		</>
	);
};

export default GetSupport;
