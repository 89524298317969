import Repository from "./Repository";

const INVESTMENT = "/client-investment";

export default {
  getInvestment() {
    return Repository.get(`${INVESTMENT}`);
  },
  getClientInvestment(id) {
    return Repository.get(`${INVESTMENT}/${id}`);
  },
};
