const initialData = {
	message: [],
};

const messageReducer = (state = initialData, action) => {
	// console.log('action', action.payload);
	switch (action.type) {
		case 'GET_MESSAGE':
			return {
				...state,
				message: action.payload,
			};

		default:
			return state;
	}
};

export default messageReducer;
