import { useState } from 'react';
import { NavLink as NavLinkRRD, Link, useHistory } from 'react-router-dom';
// nodejs library to set properties for components
import { PropTypes } from 'prop-types';

// reactstrap components
import {
	Collapse,
	DropdownMenu,
	DropdownItem,
	UncontrolledDropdown,
	DropdownToggle,
	Form,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Media,
	NavbarBrand,
	Navbar,
	NavItem,
	NavLink,
	Nav,
	Container,
	Row,
	Col,
} from 'reactstrap';
import { logout } from '../store/actions/authAction';
import { useDispatch } from 'react-redux';

var ps;

const SideBar = props => {
	const dispatch = useDispatch();
	const history = useHistory();
	const createLinks = routes => {
		return routes.map((prop, key) => {
			if (prop.isMenu != false)
				return (
					<NavItem key={key}>
						<NavLink
							to={prop.layout + prop.path}
							tag={NavLinkRRD}
							activeClassName='active'
						>
							<i className={prop.icon} />
							{prop.name}
						</NavLink>
					</NavItem>
				);
		});
	};
	const { bgColor, routes, logo } = props;
	return (
		<>
			{/* <!-- admin-sidebar --> */}
			<div className='admin-sidebar d-xl-block d-none'>
				<div className='admin-sidebar-inner'>
					<div className='sidebar-title'>
						<a href='index.html' className='sidebar-logo'>
							<img
								src={require('../assets/img/logo.png')}
								alt=''
							/>
						</a>
					</div>

					<div className='sidebar-menu'>
						<ul>
							<li>
								<Link
									className={`${
										history.location.pathname ==
										'/dashboard'
											? 'active'
											: ''
									}`}
									to='/dashboard'
								>
									<div className='icon'>
										<svg
											width='22'
											height='22'
											viewBox='0 0 22 22'
											fill='none'
											xmlns='http://www.w3.org/2000/svg'
										>
											<path
												d='M7.96875 18.594V12.0419C7.96875 11.546 7.96875 11.2981 8.06525 11.1087C8.15014 10.9421 8.28559 10.8066 8.4522 10.7217C8.6416 10.6252 8.88954 10.6252 9.38542 10.6252H11.8646C12.3605 10.6252 12.6084 10.6252 12.7978 10.7217C12.9644 10.8066 13.0999 10.9421 13.1847 11.1087C13.2813 11.2981 13.2813 11.546 13.2813 12.0419V18.594M9.75525 2.44751L3.75008 7.1182C3.34866 7.43041 3.14795 7.58652 3.00336 7.78202C2.87527 7.9552 2.77986 8.15029 2.7218 8.35771C2.65625 8.59188 2.65625 8.84615 2.65625 9.35469V15.7606C2.65625 16.7524 2.65625 17.2483 2.84926 17.6271C3.01904 17.9603 3.28994 18.2312 3.62314 18.4009C4.00194 18.594 4.49782 18.594 5.48958 18.594H15.7604C16.7522 18.594 17.2481 18.594 17.6269 18.4009C17.9601 18.2312 18.231 17.9603 18.4007 17.6271C18.5938 17.2483 18.5938 16.7524 18.5938 15.7606V9.35469C18.5938 8.84615 18.5937 8.59188 18.5282 8.35771C18.4701 8.15029 18.3747 7.9552 18.2466 7.78202C18.102 7.58652 17.9013 7.43041 17.4999 7.1182L11.4947 2.44751C11.1837 2.20557 11.0281 2.0846 10.8564 2.0381C10.7049 1.99707 10.5451 1.99707 10.3936 2.0381C10.2219 2.0846 10.0663 2.20557 9.75525 2.44751Z'
												stroke='#A9A9A9'
												strokeWidth='1.77083'
												strokeLinecap='round'
												strokeLinejoin='round'
											/>
										</svg>
									</div>
									<span className='txt'>Overview</span>
								</Link>
							</li>
							<li>
								<Link
									className={`${
										history.location.pathname == '/messages'
											? 'active'
											: ''
									}`}
									to='/messages'
								>
									<div className='icon'>
										<svg
											width='22'
											height='22'
											viewBox='0 0 22 22'
											fill='none'
											xmlns='http://www.w3.org/2000/svg'
										>
											<path
												d='M19.0365 15.9373L13.1548 10.6248M8.09528 10.6248L2.21361 15.9373M1.77087 6.19775L9.00023 11.2583C9.58564 11.6681 9.87835 11.873 10.1967 11.9523C10.478 12.0225 10.7721 12.0225 11.0533 11.9523C11.3717 11.873 11.6644 11.6681 12.2499 11.2583L19.4792 6.19775M6.02087 17.7082H15.2292C16.7168 17.7082 17.4607 17.7082 18.0289 17.4187C18.5287 17.164 18.935 16.7576 19.1897 16.2578C19.4792 15.6896 19.4792 14.9458 19.4792 13.4582V7.7915C19.4792 6.30386 19.4792 5.56004 19.1897 4.99184C18.935 4.49204 18.5287 4.08568 18.0289 3.83102C17.4607 3.5415 16.7168 3.5415 15.2292 3.5415H6.02087C4.53323 3.5415 3.78941 3.5415 3.22121 3.83102C2.72141 4.08568 2.31505 4.49204 2.06039 4.99184C1.77087 5.56004 1.77087 6.30386 1.77087 7.7915V13.4582C1.77087 14.9458 1.77087 15.6896 2.06039 16.2578C2.31505 16.7576 2.72141 17.164 3.22121 17.4187C3.78941 17.7082 4.53323 17.7082 6.02087 17.7082Z'
												stroke='#A9A9A9'
												strokeWidth='1.77083'
												strokeLinecap='round'
												strokeLinejoin='round'
											/>
										</svg>
									</div>
									<span className='txt'>Messages</span>
								</Link>
							</li>
							<li>
								<Link
									className={`${
										history.location.pathname ==
										'/my-investments'
											? 'active'
											: ''
									}`}
									to='/my-investments'
								>
									<div className='icon'>
										<svg
											width='21'
											height='21'
											viewBox='0 0 21 21'
											fill='none'
											xmlns='http://www.w3.org/2000/svg'
										>
											<path
												d='M3.5 6.825C3.5 5.35486 3.5 4.61979 3.78611 4.05827C4.03778 3.56435 4.43935 3.16278 4.93327 2.91111C5.49479 2.625 6.22986 2.625 7.7 2.625H13.3C14.7701 2.625 15.5052 2.625 16.0667 2.91111C16.5607 3.16278 16.9622 3.56435 17.2139 4.05827C17.5 4.61979 17.5 5.35486 17.5 6.825V18.375L15.0938 16.625L12.9062 18.375L10.5 16.625L8.09375 18.375L5.90625 16.625L3.5 18.375V6.825Z'
												stroke='#A9A9A9'
												strokeWidth='1.75'
												strokeLinecap='round'
												strokeLinejoin='round'
											/>
										</svg>
									</div>
									<span className='txt'>My Investments</span>
								</Link>
							</li>
							<li>
								<Link
									className={`${
										history.location.pathname ==
										'/transactions'
											? 'active'
											: ''
									}`}
									to='/transactions'
								>
									<div className='icon'>
										<svg
											width='22'
											height='22'
											viewBox='0 0 22 22'
											fill='none'
											xmlns='http://www.w3.org/2000/svg'
										>
											<path
												d='M5.31254 5.31266L7.08337 3.54183M7.08337 3.54183L5.31254 1.771M7.08337 3.54183H5.31254C3.35653 3.54183 1.77087 5.12749 1.77087 7.0835M15.9375 15.9377L14.1667 17.7085M14.1667 17.7085L15.9375 19.4793M14.1667 17.7085H15.9375C17.8935 17.7085 19.4792 16.1228 19.4792 14.1668M9.02157 5.75537C9.61132 3.46405 11.6913 1.771 14.1667 1.771C17.1007 1.771 19.4792 4.14948 19.4792 7.0835C19.4792 9.55889 17.7862 11.6389 15.4949 12.2286M12.3959 14.1668C12.3959 17.1008 10.0174 19.4793 7.08337 19.4793C4.14936 19.4793 1.77087 17.1008 1.77087 14.1668C1.77087 11.2328 4.14936 8.85433 7.08337 8.85433C10.0174 8.85433 12.3959 11.2328 12.3959 14.1668Z'
												stroke='#A9A9A9'
												strokeWidth='1.77083'
												strokeLinecap='round'
												strokeLinejoin='round'
											/>
										</svg>
									</div>
									<span className='txt'>Transactions</span>
								</Link>
							</li>
							<li>
								<Link
									className={`${
										history.location.pathname == '/account'
											? 'active'
											: ''
									}`}
									to='/account'
								>
									<div className='icon'>
										<svg
											width='22'
											height='22'
											viewBox='0 0 22 22'
											fill='none'
											xmlns='http://www.w3.org/2000/svg'
										>
											<path
												d='M17.7083 18.5938C17.7083 17.3581 17.7083 16.7403 17.5558 16.2375C17.2124 15.1056 16.3266 14.2198 15.1947 13.8765C14.692 13.724 14.0742 13.724 12.8385 13.724H8.41142C7.17576 13.724 6.55793 13.724 6.0552 13.8765C4.92328 14.2198 4.03749 15.1056 3.69413 16.2375C3.54163 16.7403 3.54163 17.3581 3.54163 18.5938M14.6093 6.64063C14.6093 8.84113 12.8255 10.625 10.625 10.625C8.42445 10.625 6.64058 8.84113 6.64058 6.64063C6.64058 4.44012 8.42445 2.65625 10.625 2.65625C12.8255 2.65625 14.6093 4.44012 14.6093 6.64063Z'
												stroke='#A9A9A9'
												strokeWidth='1.77083'
												strokeLinecap='round'
												strokeLinejoin='round'
											/>
										</svg>
									</div>
									<span className='txt'>Account</span>
								</Link>
							</li>
							<li>
								<Link
									className={`${
										history.location.pathname ==
										'/get-support'
											? 'active'
											: ''
									}`}
									to='/get-support'
								>
									<div className='icon'>
										<svg
											width='22'
											height='22'
											viewBox='0 0 22 22'
											fill='none'
											xmlns='http://www.w3.org/2000/svg'
										>
											<path
												d='M10.625 7.0835V10.6252M10.625 14.1668H10.6339M19.4792 10.6252C19.4792 15.5152 15.5151 19.4793 10.625 19.4793C5.73502 19.4793 1.77087 15.5152 1.77087 10.6252C1.77087 5.73514 5.73502 1.771 10.625 1.771C15.5151 1.771 19.4792 5.73514 19.4792 10.6252Z'
												stroke='#A9A9A9'
												strokeWidth='1.77083'
												strokeLinecap='round'
												strokeLinejoin='round'
											/>
										</svg>
									</div>
									<span className='txt'>Get Support</span>
								</Link>
							</li>
						</ul>
					</div>

					<div className='sidebar-menu mt-auto pt-4 pb-4'>
						<ul>
							<li>
								<Link to='/'>
									<div className='icon'>
										<svg
											width='22'
											height='22'
											viewBox='0 0 22 22'
											fill='none'
											xmlns='http://www.w3.org/2000/svg'
										>
											<path
												d='M14.1667 15.0521L18.5938 10.625M18.5938 10.625L14.1667 6.19792M18.5938 10.625H7.96875M7.96875 2.65625H6.90625C5.41861 2.65625 4.67479 2.65625 4.10659 2.94576C3.60678 3.20043 3.20043 3.60678 2.94576 4.10659C2.65625 4.67479 2.65625 5.41861 2.65625 6.90625V14.3438C2.65625 15.8314 2.65625 16.5752 2.94576 17.1434C3.20043 17.6432 3.60678 18.0496 4.10659 18.3042C4.67479 18.5938 5.41861 18.5938 6.90625 18.5938H7.96875'
												stroke='#A9A9A9'
												strokeWidth='1.66667'
												strokeLinecap='round'
												strokeLinejoin='round'
											/>
										</svg>
									</div>
									<span
										className='txt'
										onClick={() => {
											dispatch(logout());
										}}
									>
										Sign out
									</span>
								</Link>
							</li>
						</ul>
					</div>
				</div>
			</div>
			{/* <!-- admin-sidebar-end --> */}
		</>
	);
};
export default SideBar;
