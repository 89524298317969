import Repository from "./Repository";
import axios from "axios";

const SEND_OTP = "auth/send-otp";
const VERIFY_OTP = "auth/verify-otp";

export default {
  sendOtp(payload) {
    return axios.post(`${process.env.REACT_APP_API_URL}/${SEND_OTP}`, payload);
  },
  verifyOtp(payload) {
    return axios.post(`${process.env.REACT_APP_API_URL}/${VERIFY_OTP}`, payload);
  },
};
