// Root Reducer

import { combineReducers } from "redux";
import authUserReducer from "./authUser";
import propertiesReducer from "./propertiesReducer";
import accountReducer from "./accountReducer";
import messageReducer from "./messageReducer";
import getSupportReducer from "./getSupportReducer";
import transactionReducer from "./transactionReducer";
import authReducer from "./authReducer";
import userReducer from "./userReducer";
import investmentReducer from "./InvestmentReducer";

export let appReducer = combineReducers({
  auth: authReducer,
  authUser: authUserReducer,
  properties: propertiesReducer,
  account: accountReducer,
  message: messageReducer,
  support: getSupportReducer,
  transaction: transactionReducer,
  user: userReducer,
  investment: investmentReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT_SUCCESS") {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
