import { Provider } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Auth from './layout/Auth';
import Main from './layout/Main';
import Public from './layout/Public';
import routes from './routes';
import store from './store';

function App() {
	return (
		<> 
			<Provider store={store}>
				<BrowserRouter>
					<Switch>
						{routes.map((route) => {
							switch (route.layout) {
								case 'main':
									return (
										<Route exact path={route.path} key={route.path}>
											<Main>
												<route.component />
											</Main>
										</Route>
									);
								case 'auth':
									return (
										<Route exact path={route.path} key={route.path}>
											<Auth>
												<route.component />
											</Auth>
										</Route>
									);
								case 'public':
									return (
										<Route exact path={route.path} key={route.path}>
											<Public>
												<route.component />
											</Public>
										</Route>
									);
								default:
									return <></>
							}
						})}
						<Redirect to="/"/>
					</Switch>
				</BrowserRouter>
				<ToastContainer autoClose={2000}/>
			</Provider>
		</>
	);
}

export default App;
