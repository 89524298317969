const initialData = {
	currentUser: [],
};

const userReducer = (state = initialData, action) => {
	// console.log('action', action.payload);
	switch (action.type) {
		case 'GET_USER':
			return {
				...state,
				currentUser: action.payload,
			};

		default:
			return state;
	}
};

export default userReducer;
