import React from "react";
import exit from "../../assets/icon/exit.svg";
import bar from "../../assets/icon/bar.svg";
import logo from "../../assets/img/logo.svg";
import { Link, NavLink, useHistory } from "react-router-dom";
import { Container } from "reactstrap";
import { useSelector } from "react-redux";

function Navbar() {
  const history = useHistory();
  const { token } = useSelector((state) => state.authUser);
  const canvasOpen = () => {
    document.body.classList.add("offcanvas-opened");
  };

  return (
    <div>
      {/* <!-- offcanvas-menu --> */}
      <div className="offcanvas-wrapper blue-bg">
        <div className="offcanvas-menu">
          <nav>
            <ul>
              <li className="">
                <a href={process.env.REACT_APP_FRONTEND_URL}>Home</a>
              </li>
              <li className="">
                <a href={process.env.REACT_APP_FRONTEND_URL+"#about"}>About Us</a>
              </li>
              <li className="">
                <a href={process.env.REACT_APP_FRONTEND_URL+"#work"}>How it works</a>
              </li>
              <li className="">
                <Link to="/projects">Projects</Link>
              </li>
              <li className="">
                <a href={process.env.REACT_APP_FRONTEND_URL+"#join"}>Contact us</a>
              </li>
              <li className="">
                <a href={process.env.REACT_APP_FRONTEND_URL+"#faq"}>FAQs</a>
              </li>
            </ul>
          </nav>
        </div>

        <div className="offcanvas-link">
          <a href="">
            <span>
              <img src={exit} alt="" />
            </span>
            Log in
          </a>
        </div>
      </div>
      {/* <!-- offcanvas-menu-end --> */}

      <header className="header header-padding bg-white">
        <Container lg="fluid">
          <div className="header-wrapper d-flex align-items-center justify-content-between">
            {/* <!-- header-logo --> */}
            <div className="header-logo">
              <a href="index.html">
                <img src={logo} alt="" />
              </a>
            </div>
            {/* <!-- header-logo-end --> */}

            {/* <!-- header-menu --> */}
            <div className="header-menu d-none d-lg-block">
              <nav>
                <ul className="d-flex align-items-center justify-content-center">
                  <li className="">
                    <a href={process.env.REACT_APP_FRONTEND_URL}>Home</a>
                  </li>
                  <li className="">
                    <a href={process.env.REACT_APP_FRONTEND_URL+"#about"}>About Us</a>
                  </li>
                  <li className="">
                    <a href={process.env.REACT_APP_FRONTEND_URL+"#works"}>How it works</a>
                  </li>

                  <li>
                    <NavLink
                      to="/projects"
                      className="nav"
                      activeClassName="active_nav"
                      target="_self"
                    >
                      Projects
                    </NavLink>
                  </li>

                  <li className="">
                    <a href={process.env.REACT_APP_FRONTEND_URL+"#join"}>Contact us</a>
                  </li>
                  <li className="">
                    <a href={process.env.REACT_APP_FRONTEND_URL+"#faq"}>FAQs</a>
                  </li>
                </ul>
              </nav>
            </div>
            {/* <!-- header-menu-end --> */}

            {/* <!-- header-right --> */}
            <div className="header-right d-none d-lg-block">
              <Link className="op-btn" to="/login">
                {token ? "Dashboard" : "Login"}
              </Link>
            </div>
            {/* <!-- header-right-end --> */}

            {/* <!-- mobile-bar --> */}
            {/* <div className='mobail-bar d-lg-none'>
							<span className='bar'>
								<img src={bar} alt='' onClick={canvasOpen} />
							</span>
						</div> */}
            <a onClick={canvasOpen} className="offcanvas-open d-xl-none"></a>
            {/* <!-- mobile-bar-end --> */}
            <div className="admin-sidebar public-sidebar-mobile d-xl-none">
              <div className="admin-sidebar-inner">
                <div className="sidebar-menu">
                  <ul>
                    <li>
                      <a href={process.env.REACT_APP_FRONTEND_URL}>Home</a>
                    </li>
                    <li>
                      <a href={process.env.REACT_APP_FRONTEND_URL+"#about"}>About Us</a>
                    </li>
                    <li>
                      <a href={process.env.REACT_APP_FRONTEND_URL+"#works"}>How it works</a>
                    </li>
                    <li>
                      <a className="active" href={process.env.REACT_APP_FRONTEND_URL+"#projects"}>Projects</a>
                    </li>
                    <li >
                      <a href={process.env.REACT_APP_FRONTEND_URL+"#join"}>Contact us</a>
                    </li>
                    <li>
                      <a href={process.env.REACT_APP_FRONTEND_URL+"#faq"}>FAQs</a>
                    </li>
                  </ul>
                </div>

                <div className="sidebar-menu mt-auto pt-4 pb-4">
                  <ul>
                    <li>
                      <Link to="/login">
                        <div className="icon">
                          <svg
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M14.1667 15.0521L18.5938 10.625M18.5938 10.625L14.1667 6.19792M18.5938 10.625H7.96875M7.96875 2.65625H6.90625C5.41861 2.65625 4.67479 2.65625 4.10659 2.94576C3.60678 3.20043 3.20043 3.60678 2.94576 4.10659C2.65625 4.67479 2.65625 5.41861 2.65625 6.90625V14.3438C2.65625 15.8314 2.65625 16.5752 2.94576 17.1434C3.20043 17.6432 3.60678 18.0496 4.10659 18.3042C4.67479 18.5938 5.41861 18.5938 6.90625 18.5938H7.96875"
                              stroke="#A9A9A9"
                              strokeWidth="1.66667"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>

                        <span
                          onClick={() => {
                            // dispatch(logout());
                          }}
                          className="txt"
                        >
                          Login
                        </span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </header>
    </div>
  );
}

export default Navbar;
