import React from "react";
import clock from "../../assets/icon/clock-w.svg";
import phone from "../../assets/icon/phone-w.svg";
import fbs from "../../assets/img/social/fb.svg";
import INs from "../../assets/img/social/in.svg";
import INS from "../../assets/img/social/ins.svg";
import tws from "../../assets/img/social/tw.svg";
import footerLogo from "../../assets/img/footer-logo.svg";
import mMarkerW from "../../assets/icon/m-marker-w.svg";
import enw from "../../assets/icon/en-w.svg";
import mapImg from "../../assets/img/map.png";
import { Container } from "reactstrap";
import {Link} from "react-router-dom";

function Footer() {
  return (
    <footer className="footer footer-bg">
      <div className="footer-top">
        <Container lg="fluid">
          <div className="col-xl-11 mx-auto">
            <div className="footer-logo-m mb-5 d-lg-none text-center">
              <a href={process.env.REACT_APP_FRONTEND_URL}>
                <img src={footerLogo} alt="" />
              </a>
            </div>
            <div className="row">
              <div className="col-lg-4 col-sm-6 col-12  order-2 order-lg-0">
                <div className="footer-text">
                  <h6 className="text-white fw-semibold">
                    Oyepartner
                  </h6>
                  <p>Promoters * Developers * Builders</p>

                  <div className="footer-shedule position-relative">
                    <span>
                      <img src={clock} alt="" />
                    </span>
                    <p>
                      Mon - Sat 9:00 - 17:30, <br /> Sunday - Closed
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-sm-6 col-12  order-0 order-lg-1">
                <div className="footer-widget">
                  <h6 className="text-white fw-semibold">Company</h6>
                  <ul>
                    <li>
                      <a href={process.env.REACT_APP_FRONTEND_URL}>Home</a>
                    </li>
                    <li>
                      <Link to="/projects">Projects</Link>
                    </li>
                    <li>
                      <a href={process.env.REACT_APP_FRONTEND_URL+"/#work"}>How it works</a>
                    </li>
                    <li>
                      <a href={process.env.REACT_APP_FRONTEND_URL+"/#about"}>About us</a>
                    </li>
                    <li>
                      <a href={process.env.REACT_APP_FRONTEND_URL+"/#join"}>Contact us</a>
                    </li>
                    <li>
                      <a href={process.env.REACT_APP_FRONTEND_URL+"/#faq"}>FAQs</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 col-12  order-3 order-lg-2">
                <div className="footer-widget">
                  <h6 className="text-white fw-semibold">Our Office</h6>
                  <div className="footer-contact">
                    <div className="footer-contact-item">
                      <span>
                        <img src={mMarkerW} alt="" />
                      </span>
                        <a href="">#UG-12, Hussain Heights, <br/>MSK Mill Ring road,<br/> Kalaburagi 585103</a>
                    </div>

                    <div className="footer-contact-item">
                      <span>
                        <img src={phone} alt="" />
                      </span>
                      <div>
                        <a href="tel:+(91) 7337 823 894">+(91) 7337 823 894</a>
                      </div>
                    </div>
                    <div className="footer-contact-item">
                      <span>
                        <img src={enw} alt="" />
                      </span>
                      <a href="mailto:amjedonline@gmail.com">mo@oyepartner.com</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 col-12  order-1 order-lg-3">
                <div className="footer-location">
                  <h6 className="text-white fw-semibold">Our Location</h6>
                  <div className="footer-map">
                    <img src={mapImg} className="img-fluid" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <div className="footer-bottom">
        <Container lg="fluid">
          <div className="d-sm-flex d-block align-items-center justify-content-between">
            {/* <!-- footer-logo --> */}
            <div className="footer-logo d-none d-lg-block">
              <a href={process.env.REACT_APP_FRONTEND_URL}>
                <img src={footerLogo} alt="" />
              </a>
            </div>
            {/* <!-- footer-logo-end --> */}

            {/* <!-- footer-copy --> */}
            <div className="footer-copy ps-lg-4">
              <p className="m-0">@Oyepartner, All Rights Reserved</p>
            </div>
            {/* <!-- footer-copy-emd --> */}

            {/* <!-- footer-social --> */}
            <div className="footer-social">
              <a href="https://www.facebook.com/profile.php?id=100088758493198&sk=about">
                <img src={fbs} alt="" />
              </a>
              <a href="https://www.linkedin.com/in/amjedonline/">
                <img src={INs} alt="" />
              </a>
              <a href="">
                <img src={tws} alt="" />
              </a>
              <a href="">
                <img src={INS} alt="" />
              </a>
            </div>
            {/* <!-- footer-social --> */}
          </div>
        </Container>
      </div>
    </footer>
  );
}

export default Footer;
