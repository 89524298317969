import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RepositoryFactory } from "../../repository/RepositoryFactory";
import Repository from "../../repository/Repository";

var auth = RepositoryFactory.get("auth");

export const loginLoader = (val) => async (dispatch) => {
  dispatch({ type: "LOGIN_LOADER", payload: val });
};
export const otpLoader = (val) => async (dispatch) => {
  dispatch({ type: "OTP_LOADER", payload: val });
};

export const sendOtpAction =
  (payload, onSuccess = () => {}) =>
  async (dispatch) => {
    dispatch(loginLoader(true));

    try {
      let { data } = await auth.sendOtp(payload);

      if (data) {
        dispatch({
          type: "LOGIN_SUCCESS_MSG",
          payload: "Sending OTP...",
        });
        onSuccess();
        dispatch(loginLoader(false));
      }
    } catch (e) {
      dispatch(loginLoader(false));
      dispatch({ type: "LOGIN_FAILED" });
    }
  };

export const verifyOtpAction =
  (payload, onSuccess = () => {}) =>
  async (dispatch) => {
    dispatch(otpLoader(true));
    // console.log('payload', payload);

    try {
      let { data } = await auth.verifyOtp(payload);
      // console.log("data", data);

      if (data) {
        Repository.defaults.headers.Authorization = `Bearer ${data}`;
        setTimeout(async function () {
          await dispatch({
            type: "LOGIN_SUCCESS",
            payload: data,
          });
          dispatch(otpLoader(false));
        }, 2000);
        onSuccess("success");

        dispatch({ type: "OTP_SUCCESS" });
      }
    } catch (e) {
      dispatch(otpLoader(false));
      dispatch({ type: "LOGIN_FAILED" });
      onSuccess("fail");
    }
  };
export const logout = () => async (dispatch) => {
  localStorage.clear();
  dispatch({ type: "LOGOUT_SUCCESS" });
};
