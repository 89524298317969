import React from "react";
import logo from "../../assets/img/logo.svg";
import exit from "../../assets/icon/exit.svg";
import footerLogo from "../../assets/img/footer-logo.svg";
import formLogo from "../../assets/img/form/logo.svg";
import bar from "../../assets//icon/bar.svg";
import { Link } from "react-router-dom";
import Navbar from "../../components/otp/Navbar";
import LoginImage from "../../components/login/LoginImage";
import OTPForm from "../../components/otp/OTPForm";

function OTP() {
  return (
    <>
      <div className="m-shapes position-relative"></div>

      <div className="main-wrapper">
        {/* <!-- offcanvas-menu --> */}
        <div className="offcanvas-wrapper blue-bg">
          <div className="offcanvas-menu">
            <nav>
              <ul>
                <li className="">
                  <a href="">Home</a>
                </li>
                <li className="">
                  <a href="">About Us</a>
                </li>
                <li className="">
                  <a href="">How it works</a>
                </li>
                <li className="">
                  <a href="">Properties</a>
                </li>
                <li className="">
                  <a href="">Contact us</a>
                </li>
                <li className="">
                  <a href="">FAQs</a>
                </li>
              </ul>
            </nav>
          </div>

          <div className="offcanvas-link">
            <a href="">
              <span>
                <img src={exit} alt="" />
              </span>
              Log in
            </a>
          </div>
        </div>
        {/* <!-- offcanvas-menu-end --> */}
        <div className="overlay"></div>

        {/* <!-- wrapper-inner --> */}
        <div className="inner-wrapper position-relative">
          {/* <!-- header-area --> */}
          <header className="header header-padding bg-white">
            <div className="container">
              <div className="header-wrapper d-flex align-items-center justify-content-between">
                {/* <!-- header-logo --> */}
                <div className="header-logo">
                  <a href="index.html">
                    <img className="d-lg-block d-none" src={logo} alt="" />
                    <img className="d-lg-none" src={footerLogo} alt="" />
                  </a>
                </div>
                {/* <!-- header-logo-end --> */}

                {/* <!-- header-menu --> */}
                <div className="header-menu d-none d-lg-block">
                  <Navbar />
                </div>
                {/* <!-- header-menu-end --> */}

                {/* <!-- header-right --> */}
                <div className="header-right d-none d-lg-block">
                  {/* <Link className="op-btn" to="/login">
                    Login
                  </Link> */}
                </div>
                {/* <!-- header-right-end --> */}

                {/* <!-- mobile-bar --> */}
                <div className="mobail-bar d-lg-none">
                  <span className="bar">
                    <img src={bar} alt="" />
                  </span>
                </div>
                {/* <!-- mobile-bar-end --> */}
              </div>
            </div>
          </header>
          {/* <!-- header-area-end --> */}

          {/* <!-- main-wrapper --> */}
          <main>
            {/* <!-- form-section --> */}
            <div className="form-section">
              <div className="form-section-inner">
                <LoginImage img={formLogo} />
                <OTPForm />
              </div>
            </div>
            {/* <!-- form-section-end --> */}
          </main>
          {/* <!-- main-wrapper-end --> */}
        </div>
        {/* <!-- wrapper-inner --> */}
      </div>

      {/* <!-- modal --> */}
      <div
        className="modal contact-modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="contact-form">
              <form action="">
                <div className="row">
                  <div className="col-md-12 col-lg-6">
                    <div className="input-item">
                      <label htmlFor="">First Name*</label>
                      <div className="input-box">
                        <input
                          type="text"
                          placeholder="type your first name here..."
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-6">
                    <div className="input-item">
                      <label htmlFor="">Last Name*</label>
                      <div className="input-box">
                        <input
                          type="text"
                          placeholder="type your last name here..."
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="input-item">
                      <label htmlFor="">Contact Number*</label>
                      <div className="input-box">
                        <input
                          type="text"
                          placeholder="type your number here..."
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="input-item">
                      <label htmlFor="">Message*</label>
                      <div className="input-box">
                        <textarea
                          name=""
                          id=""
                          cols="30"
                          rows="10"
                          placeholder="type your message here..."
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="text-center submit-btn mt-4">
                      <a href="" className="op-btn">
                        Send
                      </a>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- modal-end --> */}
    </>
  );
}

export default OTP;
