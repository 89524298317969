import AuthView from '../views/auth/AuthView';
import MainView from '../views/MainView';
import Account from '../views/Account';
import Messages from '../views/Messages';
import MyInvestments from '../views/MyInvestments';
import GetSupport from '../views/GetSupport';
import Transactions from '../views/Transactions';
import Login from '../views/public/Login';
import OTP from '../views/public/OTP';
import Properties from '../views/public/Properties';
import ProjectDetailsView from '../views/public/ProjectDetailsView';

let routes = [
	{
		path: '/login',
		name: 'Login',
		component: Login,
		layout: 'auth',
	},
	{
		path: '/otp',
		name: 'otp',
		component: OTP,
		layout: 'auth',
	},
	{
		path: '/',
		name: 'Properties',
		component: Properties,
		layout: 'public',
	},
	{
		path: '/projects',
		name: 'Projects',
		component: Properties,
		layout: 'public',
	},
	{
		path: '/project-details/:id',
		name: 'Project Details',
		component: ProjectDetailsView,
		layout: 'public',
	},
	{
		path: '/messages',
		name: 'Messages',
		icon: 'ni ni-email-83',
		component: Messages,
		layout: 'main',
	},
	{
		path: '/my-investments',
		name: 'My Investments',
		icon: 'ni ni-archive-2',
		component: MyInvestments,
		layout: 'main',
	},
	{
		path: '/transactions',
		name: 'Transactions',
		icon: 'ni ni-collection',
		component: Transactions,
		layout: 'main',
	},
	{
		path: '/account',
		name: 'Account',
		icon: 'ni ni-single-02',
		component: Account,
		layout: 'main',
	},
	{
		path: '/get-support',
		name: 'Get Support',
		icon: 'ni ni-support-16',
		component: GetSupport,
		layout: 'main',
	},
	{
		path: '/auth',
		component: AuthView,
		layout: 'auth',
		isMenu: false,
	},
	{
		path: '/dashboard',
		name: 'Overview',
		component: MainView,
		layout: 'main',
		isMenu: false,
	},
];
export default routes;
