import React from 'react';
import exit from '../../assets/icon/exit.svg';
import logo from '../../assets/img/logo.svg';
import footerLogo from '../../assets/img/footer-logo.svg';
import formLogo from '../../assets/img/form/logo.svg';
import { Link } from 'react-router-dom';
import LoginImage from '../../components/login/LoginImage';
import LoginForm from '../../components/login/LoginForm';

function Login() {
	return (
		<>
			<div className='main-wrapper'>
				<div className='inner-wrapper '>
					{/* <!-- main-wrapper --> */}
					<main>
						{/* <!-- form-section --> */}
						<div className='form-section'>
							<div className='form-section-inner'>
								<LoginImage img={formLogo} />

								<LoginForm />
							</div>
						</div>
						{/* <!-- form-section-end --> */}
					</main>
					{/* <!-- main-wrapper-end --> */}
				</div>
				{/* <!-- wrapper-inner --> */}
			</div>

			{/* <!-- modal --> */}
			<div
				className='modal contact-modal fade'
				id='exampleModal'
				tabIndex='-1'
				aria-labelledby='exampleModalLabel'
				aria-hidden='true'
			>
				<div className='modal-dialog modal-dialog-centered'>
					<div className='modal-content'>
						<div className='contact-form'>
							<form action=''>
								<div className='row'>
									<div className='col-md-12 col-lg-6'>
										<div className='input-item'>
											<label htmlFor=''>First Name*</label>
											<div className='input-box'>
												<input
													type='text'
													placeholder='type your first name here...'
												/>
											</div>
										</div>
									</div>
									<div className='col-md-12 col-lg-6'>
										<div className='input-item'>
											<label htmlFor=''>Last Name*</label>
											<div className='input-box'>
												<input
													type='text'
													placeholder='type your last name here...'
												/>
											</div>
										</div>
									</div>
									<div className='col-md-12'>
										<div className='input-item'>
											<label htmlFor=''>
												Contact Number*
											</label>
											<div className='input-box'>
												<input
													type='text'
													placeholder='type your number here...'
												/>
											</div>
										</div>
									</div>
									<div className='col-md-12'>
										<div className='input-item'>
											<label htmlFor=''>Message*</label>
											<div className='input-box'>
												<textarea
													name=''
													id=''
													cols='30'
													rows='10'
													placeholder='type your message here...'
												></textarea>
											</div>
										</div>
									</div>
									<div className='col-12'>
										<div className='text-center submit-btn mt-4'>
											<a href='' className='op-btn'>
												Send
											</a>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			{/* <!-- modal-end --> */}
		</>
	);
}

export default Login;
