import axios from "axios";
import Repository from "./Repository";
const ADDRESS = "/address";
const PANDETAILS = "/pan-details";
const ACCOUNT = "/client-bank-account";
const NOMINEE = "/client-nominee";
const FILE = "file/upload";
const CLIENT = "/client";

export default {
  getAddress(payload) {
    return Repository.get(`${ADDRESS}`);
  },
  updateAddress(id, payload) {
    return Repository.put(`${ADDRESS}`, payload);
  },
  addAddress(payload) {
    return Repository.post(`${ADDRESS}`, payload);
  },
  getPanDetails(payload) {
    return Repository.get(`${PANDETAILS}`);
  },
  updatePanDetails(payload) {
    return Repository.post(`${PANDETAILS}`, payload);
    // console.log("payload", payload);
    // let form = new FormData();

    // form.append("file", payload.panImage);
    // console.log("form", form);
    // return Repository.post(`${FILE}/upload?token=${payload.token}`, form);
  },

  getPanImageUrl(payload) {
    // let form = new FormData();
    // form.append('file', payload);
    return Repository.get(`${PANDETAILS}/uploadUrl`);
  },

  uploadPanImageToS3(payload) {
    return axios.put(`${payload.url}`, payload.file, {
      headers: { "Content-Type": "application/octet-stream" },
    });
  },
  uploadPersonalImageToS3(payload) {
    return axios.put(`${payload.url}`, payload.file, {
      headers: { "Content-Type": "application/octet-stream" },
    });
  },

  getAccountDetails(payload) {
    return Repository.get(`${ACCOUNT}`);
  },
  getAllBankAcconts() {
    return Repository.get(`${ACCOUNT}`);
  },
  updateAccountDetails(id, payload) {
    return Repository.put(`${ACCOUNT}/${id}`, payload);
  },
  addAccountDetails(payload) {
    return Repository.post(`${ACCOUNT}`, payload);
  },
  getNomineeDetails(payload) {
    return Repository.get(`${NOMINEE}`);
  },
  getListNomineeDetails(payload) {
    return Repository.get(`${NOMINEE}`);
  },
  updateNomineeDetails(id, payload) {
    return Repository.put(`${NOMINEE}/${id}`, payload);
  },
  addNomineeDetails(payload) {
    return Repository.post(`${NOMINEE}`, payload);
  },
  getPersonalDetails() {
    return Repository.get(`${CLIENT}`);
  },
  getPersonalDetailsImage() {
    return Repository.get(`${CLIENT}/profilePicUploadUrl`);
  },
  updatePersonalDetails(payload) {
    return Repository.put(`${CLIENT}`, payload);
  },
};
