function InvestmentPagination() {
	return (
		<div className='pagination w-100 mt-0'>
			<div className='left'>
				<p className='mb-0'>
					Showing <span className='fw-600'>10</span> of{' '}
					<span className='fw-600'>30</span>
				</p>
			</div>
			<div className='right'>
				<ul className='pagi-nav'>
					<li className='corner'>
						<a href='#'>Previous</a>
					</li>
					<li>
						<a className='fw-500' href='#'>
							1
						</a>
					</li>
					<li>
						<a className='fw-500 active' href='#'>
							2
						</a>
					</li>
					<li>
						<a className='fw-500' href='#'>
							3
						</a>
					</li>
					<li className='corner'>
						<a href='#'>Next</a>
					</li>
				</ul>
			</div>
		</div>
	);
}

export default InvestmentPagination;
