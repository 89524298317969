import { useEffect, useRef, useState, version } from "react";
import { addDays } from "date-fns";
import { getTransaction } from "../store/actions/transactionAction";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import TransactionTable from "../components/transactions/TransactionTable";
import TransactionPagination from "../components/transactions/TranactionPagination";

const Transactions = () => {
  const dispatch = useDispatch();

  const refOne = useRef(null);
  const [showPicker, setShowPicker] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);
  const { transactionList } = useSelector((state) => state.transaction);
  // console.log('transactionListt', transactionList.length);

  useEffect(() => {
    updatePredicate();
    window.addEventListener("resize", updatePredicate);
    return () => window.removeEventListener("resize", updatePredicate);
  }, []);
  const updatePredicate = () => {
    setIsSmallScreen(window.innerWidth < 1024);
  };
  useEffect(() => {
    dispatch(getTransaction());
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, {
      capture: true,
    });
    return () => {
      document.removeEventListener("click", handleClickOutside, {
        capture: true,
      });
    };
  }, []);
  const handleClickOutside = (e) => {
    if (!refOne?.current?.contains(e?.target)) {
      setShowPicker(false);
    }
  };

  return (
    <>
      {/* <!-- page-title-mobile --> */}
      <div className="page-title-mobile d-xl-none">
        <h3 className="mb-0">Transactions</h3>
      </div>

      {/* <!-- table-box --> */}
      <div className="table-box">
        <TransactionTable data={transactionList} />
        <TransactionPagination data={transactionList} />

        {/* { transactionList.length !== 0 && <TransactionPagination /> } */}
      </div>
      {/* <!-- table-box-end --> */}
    </>
  );
};

export default Transactions;
