const initialData = {
  loading: false,
  error: "",
};

const getSupportReducer = (state = initialData, action) => {
  // console.log('action', action.payload);
  switch (action.type) {
    case "SUPPORT_LOADER":
      return {
        ...state,
        loading: action.payload,
      };
    case "ERROR":
      return {
        error: action.payload,
      };

    default:
      return state;
  }
};

export default getSupportReducer;
