import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Button, Spinner } from "reactstrap";
import { verifyOtpAction } from "../../store/actions/authAction";
import OtpInput from "react-otp-input";

function OTPForm() {
  const [first, setFirst] = useState("");
  const [second, setSecond] = useState("");
  const [third, setThird] = useState("");
  const [fourth, setFourth] = useState("");
  const [otp, setOtp] = useState("");
  const [msg, setMsg] = useState("");
  const [color, setColor] = useState("");
  // console.log("otp", otp);
  const { otpLoading } = useSelector((state) => state.auth);
  const { successMessage, errorMessage } = useSelector(
    (state) => state.authUser
  );
  const [successVisible, setSuccessVisible] = useState(false);
  const [failVisible, setFailVisible] = useState(false);

  var x = localStorage.getItem("phone");
  // console.log("x", typeof x);
  const specificNumber = () => {
    const y = String(x).slice(0, 3);
    const z = y + "*******" + String(x).slice(-3);
    return z;
  };
  const dispatch = useDispatch();
  const handleChange = (otp) => {
    setOtp(otp);
    setMsg("");
  };
  return (
    <>
      <div className="form-fill-side">
        <div className="form-fill-side-inner">
          <div className="form-sss">
            <ToastContainer autoClose={500} />

            <form
              onSubmit={(e) => {
                e.preventDefault();
                const data = {
                  // otp: first + second + third + fourth,
                  otp: otp,
                  mobile: x,
                };
                dispatch(
                  verifyOtpAction(data, (res) => {
                    if (res && res == "success") {
                      setSuccessVisible(true);
                      setTimeout(() => {
                        setSuccessVisible(false);
                      }, 3000);
                    } else if (res && res == "fail") {
                      setFailVisible(true);
                      setTimeout(() => {
                        setFailVisible(false);
                      }, 3000);
                    }
                  })
                );
                // setTimeout(() => {
                //   setVisible(false);
                // }, 3000);
              }}
            >
              <div className="form-inner">
                <div className="form-title">
                  <div className="form-in-cont-p">
                    <h3>OTP</h3>
                    <p>
                      Please enter the OTP we sent you on your mobile number{" "}
                      {specificNumber()}
                    </p>
                  </div>
                </div>

                <div className="form-main">
                  <div className="form-in-cont-p">
                    <div className="single-input-x">
                      <label htmlFor="" className="label-x">
                        The code*
                      </label>
                      <div className="input-x-outer input-otp-wrap">
                        <OtpInput
                          value={otp}
                          onChange={handleChange}
                          numInputs={4}
                          isInputNum={true}
                          separator={<span> </span>}
                          // containerStyle='otp-container'
                          inputStyle="otp-input input-xx input-x-ns"
                        />
                        {/* <input
													type='text'
													className='input-x input-x-ns'
													value={first}
													maxLength='1'
													onChange={e =>
														setFirst(e.target.value)
													}
												/>
												<input
													type='text'
													className='input-x input-x-ns'
													value={second}
													maxLength='1'
													onChange={e =>
														setSecond(
															e.target.value
														)
													}
												/>
												<input
													type='text'
													className='input-x input-x-ns'
													value={third}
													maxLength='1'
													onChange={e =>
														setThird(e.target.value)
													}
												/>
												<input
													type='text'
													className='input-x input-x-ns'
													value={fourth}
													maxLength='1'
													onChange={e =>
														setFourth(
															e.target.value
														)
													}
												/> */}
                      </div>
                      {successVisible ? (
                        <span className={`text-success py-3`}>
                          {successMessage}
                        </span>
                      ) : (
                        ""
                      )}
                      {failVisible && (
                        <span className={`text-danger py-3`}>
                          {errorMessage}
                        </span>
                      )}
                    </div>
                    <div className="form-bottom">
                      <Button
                        className="op-btn submit-button w-100"
                        // to='/dashboard'
                        disabled={otp.length != 4 || otpLoading}
                        type="submit"
                      >
                        {otpLoading ? <Spinner size="sm" /> : "Verify"}
                      </Button>
                      <p className="bt-sde">
                        <span>Didn't recieve OTP? </span>
                        <a href="/login">
                          <strong>Resend</strong>
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default OTPForm;
