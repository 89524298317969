import {Link} from "react-router-dom";

function Navbar() {
	return (
		<nav>
			<ul className='d-flex align-items-center justify-content-center'>
				<li className=''>
					<a href={process.env.REACT_APP_FRONTEND_URL}>Home</a>
				</li>
				<li className=''>
					<a href={process.env.REACT_APP_FRONTEND_URL+"/#about"}>About Us</a>
				</li>
				<li className=''>
					<a href={process.env.REACT_APP_FRONTEND_URL+"/#works"}>How it works</a>
				</li>
				<li className=''>
					<Link to='/projects'>Projects</Link>
				</li>
				<li className=''>
					<a href={process.env.REACT_APP_FRONTEND_URL+"/#join"}>Contact us</a>
				</li>
				<li className=''>
					<a href={process.env.REACT_APP_FRONTEND_URL+"/#faq"}>FAQs</a>
				</li>
			</ul>
		</nav>
	);
}

export default Navbar;
