import Repository from "./Repository";
import axios from "axios";

const PROJECT = "project";

export default {
  getListProjects() {
    return axios.get(`${process.env.REACT_APP_API_URL}/${PROJECT}`);
  },
  getPropertyDetails(id) {
    return axios.get(`${process.env.REACT_APP_API_URL}/${PROJECT}/${id}`);
  },
};
