import { RepositoryFactory } from "../../repository/RepositoryFactory";
import { toast } from "react-toastify";
var support = RepositoryFactory.get("support");

export const addSupportLoader = (val) => async (dispatch) => {
  dispatch({ type: "SUPPORT_LOADER", payload: val });
};

export const getSupportAct =
  (obj, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      dispatch(addSupportLoader(true));

      let { data } = await support.getSupport(obj);

      if (data) {
        // toast.success("Your request have been submitted successfully");
        dispatch({
          type: "MESSAGE_SUCCESS",
          payload: "Your request has been submitted.",
        });
        dispatch(addSupportLoader(false));
        onSuccess("pass");
      }
    } catch (e) {
      // toast.error(e.response.data.message);
      dispatch({ type: "ERROR", payload: e.response.data.message });
      dispatch(addSupportLoader(false));
      onSuccess("fail");
    }
  };
