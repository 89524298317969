import icon1 from "../../assets/icon/check.svg";
import icon2 from "../../assets/icon/calendar.svg";
import icon3 from "../../assets/icon/switch-vertical-01.svg";
import icon4 from "../../assets/icon/bookmark-white.svg";
import icon5 from "../../assets/icon/trash-01.svg";
import { useEffect, useRef, useState } from "react";
import yellow from "../../assets/icon/i-yellow.svg";
import { DateRangePicker } from "react-date-range";
import { addDays, setDate } from "date-fns";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from "reactstrap";
import { useDispatch } from "react-redux";
import { updateMessageAct } from "../../store/actions/messageAction";

function Table(props) {
  // console.log("props", props.data.rows);
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");
  const [date, setDate] = useState("");
  const [id, setId] = useState("");
  // console.log('id', id);
  const clearFields = () => {
    setMessage("");
    setSubject("");
    setDate("");
    setId("");
  };
  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);
  const [showPicker, setShowPicker] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const refOne = useRef(null);
  const [modal, setModal] = useState(false);
  const dispatch = useDispatch();

  const toggle = () => setModal(!modal);
  const handleOnClick = (event) => {
    let calendarDropdown = document.getElementById("calendar-dropdown");
    if (showPicker && !calendarDropdown.contains(event.target)) {
      setShowPicker(false);
    }
  };
  useEffect(() => {
    window.addEventListener("click", handleOnClick);
    return () => {
      window.removeEventListener("click", handleOnClick);
    };
  });

  return (
    <div className="table-box">
      {/* <div className="table-box-title">
        <div className="tbl-i-menu">
          <ul>
            <li>
              <a href="#">
                <img src={icon1} alt="" />
              </a>
            </li>
            <li>
              <a
                ref={refOne}
                className="range-picker position-relative"
                onClick={() => setShowPicker(!showPicker)}
                id="calendar-dropdown"
              >
                <img src={icon2} alt="" />
                {/* <!-- <input type="text"> --> */}
      {/* {showPicker ? (
                  <DateRangePicker
                    className="rangepicker"
                    rangeColors={["rgb(232, 62, 140)"]}
                    onChange={(item) => setRange([item.selection])}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={2}
                    ranges={range}
                    direction={isSmallScreen ? "vertical" : "horizontal"}
                  />
                ) : (
                  ""
                )}
              </a>
            </li>
            <li>
              <a href="#">
                <img src={icon3} alt="" />
              </a>
            </li> */}
      {/* <li>
							<a href='#'>
								<img src={icon4} alt='' />
							</a>
						</li> */}
      {/* <li>
							<a href='#'>
								<img src={icon5} alt='' />
							</a>
						</li> */}
      {/* </ul>
        </div>

        <div className="t-search">
          <input
            type="text"
            className="input d-md-block d-none"
            placeholder="Search..."
          />
          <button
            type="text"
            className="input input-button d-md-none"
            placeholder="Search..."
          >
            <img
              src={require("../../assets/icon/search-refraction.svg")}
              alt=""
            />
          </button>
        </div> 
      </div> */}
      <div className="table-model table-model-bordered table-mdl-str2 d-md-block d-none">
        <table>
          <thead>
          <tr>
            <td></td>
            <td>DATE</td>
            <td>SUBJECT</td>
            <td>FROM ACCOUNT</td>
            <td></td>
          </tr>
          </thead>
          <tbody>
            {props.data.rows == 0 ? (
              <tr>
                <td className="text-center" colSpan="6">
                  There are no messages yet.
                </td>
              </tr>
            ) : (
              props.data.rows?.map((el, id) => {
                return (
                  <tr key={id}>
                    <td>
                      <div className="input-outer">
                        {/* <input
                          type="checkbox"
                          className="checkbox-model checkbox"
                        /> */}
                      </div>
                    </td>
                    <td>{el.date}</td>
                    <td>
                      <div className="t-str2-label">
                        <div className="icon">
                          <img src={yellow} alt="" />
                        </div>
                        <span className="txt">{el.subject}</span>
                      </div>
                    </td>
                    <td>
                      <div
                        // className=' fw-bold'
                        className={`d-in1-line ${
                          el.marked == true ? "fw-normal" : "fw-bold"
                        }`}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          toggle();
                          setMessage(el.content);
                          setSubject(el.subject);
                          setDate(el.date);
                          setId(el.id);
                        }}
                      >
                        {el.content}
                      </div>
                    </td>
                    <td>
                      {/* <a href="javascript:void(0)" className="td-bookmark">
                        <svg
                          width="15"
                          height="19"
                          viewBox="0 0 15 19"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.35046 5.78682C1.35046 4.32492 1.35046 3.59397 1.63497 3.0356C1.88523 2.54444 2.28455 2.14511 2.77571 1.89486C3.33408 1.61035 4.06503 1.61035 5.52693 1.61035H9.35537C10.8173 1.61035 11.5482 1.61035 12.1066 1.89486C12.5977 2.14511 12.9971 2.54444 13.2473 3.0356C13.5318 3.59397 13.5318 4.32492 13.5318 5.78682V17.2721L7.44115 13.7917L1.35046 17.2721V5.78682Z"
                            stroke="#142C4B"
                            strokeWidth="1.7402"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </a> */}
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
      <div className="mbl-table mbl-table-str2 d-md-none">
        <div className="tbl-hd">
          <div className="tbl-tr">
            <div className="column str-col">DATE</div>
            <div className="column end-col">SUBJECT</div>
          </div>
        </div>
        <div className="tbl-bd">
          {props.data.rows == 0 ? (
            <p className="text-center mt-3">There are no messages yet.</p>
          ) : (
            props.data.rows?.map((el, id) => {
              return (
                <div className="tbl-tr">
                  <div className="row1" key={id}>
                    <div className="left str-col">
                      {/* <input
                        type="checkbox"
                        className="checkbox checkbox-model"
                      /> */}
                      <h5>{el.date}</h5>
                    </div>
                    <div className="right end-col">
                      <div className="t-str2-label">
                        <div className="icon">
                          <img src={yellow} alt="" />
                        </div>
                        <span className="txt">{el.subject}</span>
                      </div>
                      <a href="#" className="td-bookmark">
                        {/* <svg
                          width="15"
                          height="19"
                          viewBox="0 0 15 19"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.35046 5.78682C1.35046 4.32492 1.35046 3.59397 1.63497 3.0356C1.88523 2.54444 2.28455 2.14511 2.77571 1.89486C3.33408 1.61035 4.06503 1.61035 5.52693 1.61035H9.35537C10.8173 1.61035 11.5482 1.61035 12.1066 1.89486C12.5977 2.14511 12.9971 2.54444 13.2473 3.0356C13.5318 3.59397 13.5318 4.32492 13.5318 5.78682V17.2721L7.44115 13.7917L1.35046 17.2721V5.78682Z"
                            stroke="#142C4B"
                            strokeWidth="1.7402"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </svg> */}
                      </a>
                    </div>
                  </div>
                  <div
                    className="row2 "
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      toggle();
                      setMessage(el.content);
                      setSubject(el.subject);
                      setDate(el.date);
                      setId(el.id);
                    }}
                  >
                    <p
                      className={`d-in1-line ${
                        el.marked == false ? "fw-bold" : "fw-normal"
                      }`}
                    >
                      {el.content}
                    </p>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
      <div className="table-box-footer">
        <div className="td-pagination">
          <div className="pagination">
            <div className="left">
              <p className="mb-0">
                Total <span className="fw-600">{props.data?.rows?.length}</span>
                {/* of{" "}
                <span className="fw-600">30</span> */}
              </p>
            </div>
            {/* <div className="right">
              <ul className="pagi-nav">
                <li className="corner">
                  <a href="javascript:void(0)">Previous</a>
                </li>
                <li>
                  <a className="fw-500 active" href="javascript:void(0)">
                    1
                  </a>
                </li>
                <li>
                  <a className="fw-500 " href="javascript:void(0)">
                    2
                  </a>
                </li>
                <li>
                  <a className="fw-500" href="javascript:void(0)">
                    3
                  </a>
                </li>
                <li className="corner">
                  <a href="javascript:void(0)">Next</a>
                </li>
              </ul>
            </div> */}
          </div>
        </div>
      </div>
      <Modal isOpen={modal}>
        <ModalHeader
          toggle={() => {
            toggle();
            const data = {
              marked: true,
              subject: subject,
              content: message,
            };
            const obj = { data: data };
            dispatch(updateMessageAct(id, obj));
            clearFields();
          }}
        >
          Message
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <label>Title: </label> {subject}
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <label>Date: </label> {date}
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <label>Message: </label> <p>{message}</p>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default Table;
