function Login(props) {
	return (
		<div className='desk-img-side'>
			<div className='logo-in'>
				<img src={props.img} alt='Oyepartner' />
			</div>
		</div>
	);
}

export default Login;
