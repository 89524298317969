import { RepositoryFactory } from "../../repository/RepositoryFactory";
var user = RepositoryFactory.get("user");

export const getCurrentUser = (obj) => async (dispatch) => {
  try {
    // dispatch(addSupportLoader(true));

    let { data } = await user.getCurrentUser(obj);
    // console.log('dataa', data);

    if (data) {
      dispatch({ type: "GET_USER", payload: data });
    }
  } catch (e) {
    // alert(e.response.data.message);
  }
};
